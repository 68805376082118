<template>
	<div class="resume-wrap content-wrap">
		<Spin fix v-if="loading"></Spin>
		<!-- 基本信息 -->
		<div class="resume" id="printPdf">
			<div class="left">
				<div :class="['ava-view', { ava: entry !== 'view' }]">
					<div class="edit">
						<span @click.stop="profilePictureVisible = true">
							<Icon type="md-create" />
						</span>
					</div>
					<img v-if="!!avatar" :src="avatar" />
					<img v-else :src="defaultImg" />
				</div>
				<p class="line"></p>
				<div class="base-info">
					<!--<div class="edit">
            <span @click.stop="editBaseInfo">
              <Icon type="md-create" />
            </span>
          </div>-->
					<h2 class="real-name">
						{{ userInfo.realName }}
					</h2>
					<p>
						<Icon type="md-person" />
						{{ userInfo.sexName || "--" }}
					</p>
					<p>
						<Icon type="md-tablet-portrait" />
						{{ userInfo.phone || "--" }}
					</p>
					<p>
						<Icon type="md-mail" />
						{{ userInfo.username || "--" }}
					</p>
				</div>
			</div>
			<div class="right">
				<div class="btn-wrap">
					<!--<Button @click="changePasswordVisible = true">修改登录密码</Button>-->
					<Button @click="printPDF">打印简历</Button>
					<Button v-if="entry !== 'view'" type="primary" @click="saveHandle"
						>保存简历</Button
					>
				</div>
				<div class="form-wrap">
					<Form :model="userCv" :rules="rules" ref="userCVForm">
						<!-- 教育经历 -->
						<div class="module">
							<h3 class="title">
								<Icon type="md-book" />
								教育经历
								<Button
									icon="md-add"
									v-if="entry !== 'view'"
									@click="addHandle('edu')"
									>添加</Button
								>
							</h3>
							<draggable
								v-model="userCv.edu"
								:disabled="entry === 'view'"
								:options="{ group: 'dataitem' }"
							>
								<div
									class="item"
									v-for="(child, index) in userCv.edu"
									:key="index"
									@click="editHandle('edu', child)"
								>
									<div class="delete" v-if="entry !== 'view'">
										<span @click.stop="deleteHandle('edu', index)">
											<Icon type="ios-trash" />
										</span>
									</div>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<DatePicker
													:disabled="entry === 'view'"
													type="daterange"
													style="width: 100%"
													:value="child.time"
													:format="'yyyy-MM-dd'"
													@on-change="v => (child.time = v)"
													placeholder="教育及培训时间"
												>
												</DatePicker>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.institution"
													:disabled="entry === 'view'"
													placeholder="所在院校或培训机构名称"
												/>
											</FormItem>
										</Col>
									</Row>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.degree"
													:disabled="entry === 'view'"
													placeholder="所获学位或学历"
												/>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.profession"
													:disabled="entry === 'view'"
													placeholder="专业"
												/>
											</FormItem>
										</Col>
									</Row>
								</div>
							</draggable>
						</div>
						<!-- 工作经历 -->
						<div class="module">
							<h3 class="title">
								<Icon type="md-build" />
								工作经历
								<Button
									icon="md-add"
									v-if="entry !== 'view'"
									@click="addHandle('works')"
									>添加</Button
								>
							</h3>
							<draggable
								v-model="userCv.works"
								:disabled="entry === 'view'"
								:options="{ group: 'works' }"
							>
								<div
									class="item"
									v-for="(child, index) in userCv.works"
									:key="index"
									@click="editHandle('works', child)"
								>
									<div class="delete" v-if="entry !== 'view'">
										<span @click.stop="deleteHandle('works', index)">
											<Icon type="ios-trash" />
										</span>
									</div>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<DatePicker
													type="daterange"
													style="width: 100%"
													:value="child.time"
													format="yyyy-MM-dd"
													:disabled="entry === 'view'"
													@on-change="v => (child.time = v)"
													placeholder="时间"
												>
												</DatePicker>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.organization"
													:disabled="entry === 'view'"
													placeholder="所在工作机构"
												/>
											</FormItem>
										</Col>
									</Row>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.position"
													:disabled="entry === 'view'"
													placeholder="职位职称"
												/>
											</FormItem>
										</Col>
									</Row>
								</div>
							</draggable>
						</div>
						<!-- 学会任职 -->
						<div class="module">
							<h3 class="title">
								<Icon type="md-contact" />
								学会任职
								<Button
									icon="md-add"
									v-if="entry !== 'view'"
									@click="addHandle('temping')"
									>添加</Button
								>
							</h3>
							<draggable
								v-model="userCv.temping"
								:disabled="entry === 'view'"
								:options="{ group: 'temping' }"
							>
								<div
									class="item"
									v-for="(child, index) in userCv.temping"
									:key="index"
									@click="editHandle('temping', child)"
								>
									<div class="delete" v-if="entry !== 'view'">
										<span @click.stop="deleteHandle('temping', index)">
											<Icon type="ios-trash" />
										</span>
									</div>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.name"
													:disabled="entry === 'view'"
													placeholder="学会名称"
												/>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.position"
													:disabled="entry === 'view'"
													placeholder="职务"
												/>
											</FormItem>
										</Col>
									</Row>
								</div>
							</draggable>
						</div>
						<!-- 科研课题和获奖 -->
						<div class="module">
							<h3 class="title">
								<Icon type="md-trophy" />
								科研课题和获奖
								<Button
									icon="md-add"
									v-if="entry !== 'view'"
									@click="addHandle('award')"
									>添加</Button
								>
							</h3>
							<draggable
								v-model="userCv.award"
								:disabled="entry === 'view'"
								:options="{ group: 'award' }"
							>
								<div
									class="item"
									v-for="(child, index) in userCv.award"
									:key="index"
									@click="editHandle('award', child)"
								>
									<div class="delete" v-if="entry !== 'view'">
										<span @click.stop="deleteHandle('award', index)">
											<Icon type="ios-trash" />
										</span>
									</div>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.questionNum"
													:disabled="entry === 'view'"
													placeholder="课题编号"
												/>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<DatePicker
													type="daterange"
													style="width: 100%"
													:value="child.date"
													format="yyyy-MM-dd"
													:disabled="entry === 'view'"
													@on-change="v => (child.date = v)"
													placeholder="起止日期"
												>
												</DatePicker>
											</FormItem>
										</Col>
									</Row>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.source"
													:disabled="entry === 'view'"
													placeholder="课题来源"
												/>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.name"
													:disabled="entry === 'view'"
													placeholder="课题名称"
												/>
											</FormItem>
										</Col>
									</Row>
								</div>
							</draggable>
						</div>
						<!-- 继续教育 -->
						<div class="module">
							<h3 class="title">
								<Icon type="md-list-box" />
								继续教育（特别注明有无接受过GCP培训）
								<Button
									icon="md-add"
									v-if="entry !== 'view'"
									@click="addHandle('training')"
									>添加</Button
								>
							</h3>
							<draggable
								v-model="userCv.training"
								:disabled="entry === 'view'"
								:options="{ group: 'training' }"
							>
								<div
									class="item"
									v-for="(child, index) in userCv.training"
									:key="index"
									@click="editHandle('training', child)"
								>
									<div class="delete" v-if="entry !== 'view'">
										<span @click.stop="deleteHandle('training', index)">
											<Icon type="ios-trash" />
										</span>
									</div>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<DatePicker
													type="daterange"
													style="width: 100%"
													:value="child.date"
													format="yyyy-MM-dd"
													:disabled="entry === 'view'"
													@on-change="v => (child.date = v)"
													placeholder="起止日期"
												>
												</DatePicker>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.name"
													:disabled="entry === 'view'"
													placeholder="继续教育项目名称"
												/>
											</FormItem>
										</Col>
									</Row>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.organizers"
													:disabled="entry === 'view'"
													placeholder="举办单位"
												/>
											</FormItem>
										</Col>
									</Row>
								</div>
							</draggable>
						</div>
						<!--培训记录-->
						<div class="module">
							<h3 class="title">
								<Icon type="md-medkit" />
								培训记录
								<Button
									icon="md-add"
									v-if="entry !== 'view'"
									@click="handleAddTrain"
									>添加</Button
								>
							</h3>
							<CtmsDataGrid
								:columns="newColumns"
								:data="listData"
								:page="false"
							>
							</CtmsDataGrid>
						</div>
						<!-- GCP培训情况 -->
						<div class="module">
							<h3 class="title">
								<Icon type="md-medkit" />
								GCP培训情况
							</h3>
							<Row :gutter="32">
								<Col span="24">
									<FormItem label="是否接受过GCP培训：" prop="gcp">
										<RadioGroup v-model="userCv.gcp" id="gcp">
											<Radio label="2" :disabled="entry === 'view'">是</Radio>
											<Radio label="1" :disabled="entry === 'view'">否</Radio>
										</RadioGroup>
									</FormItem>
								</Col>
							</Row>
						</div>
						<!-- 临床研究经历 -->
						<div class="module">
							<h3 class="title">
								<Icon type="md-cube" />
								曾经参加过的临床研究项目
								<Button
									icon="md-add"
									v-if="entry !== 'view'"
									@click="addHandle('clinical')"
									>添加</Button
								>
							</h3>
							<draggable
								v-model="userCv.clinical"
								:disabled="entry === 'view'"
								:options="{ group: 'clinical' }"
							>
								<div
									class="item"
									v-for="(child, index) in userCv.clinical"
									:key="index"
									@click="editHandle('clinical', child)"
								>
									<div class="delete" v-if="entry !== 'view'">
										<span @click.stop="deleteHandle('clinical', index)">
											<Icon type="ios-trash" />
										</span>
									</div>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<DatePicker
													type="date"
													style="width: 100%"
													:value="child.time"
													format="yyyy-MM-dd"
													:disabled="entry === 'view'"
													@on-change="v => (child.time = v)"
													placeholder="参加临床试验的时间"
												>
												</DatePicker>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.indication"
													:disabled="entry === 'view'"
													placeholder="研究适应症"
												/>
											</FormItem>
										</Col>
									</Row>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.stage"
													:disabled="entry === 'view'"
													placeholder="临床分期"
												/>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.responsibily"
													:disabled="entry === 'view'"
													placeholder="临床试验中的职责"
												/>
											</FormItem>
										</Col>
									</Row>
								</div>
							</draggable>
						</div>
						<!-- 出版专著和发表论文（特别注明与临床试验相关的论文） -->
						<div class="module">
							<h3 class="title">
								<Icon type="md-document" />
								出版专著和发表论文（特别注明与临床试验相关的论文）
								<Button
									icon="md-add"
									v-if="entry !== 'view'"
									@click="addHandle('thesis')"
									>添加</Button
								>
							</h3>
							<draggable
								v-model="userCv.thesis"
								:disabled="entry === 'view'"
								:options="{ group: 'thesis' }"
							>
								<div
									class="item"
									v-for="(child, index) in userCv.thesis"
									:key="index"
									@click="editHandle('thesis', child)"
								>
									<div class="delete" v-if="entry !== 'view'">
										<span @click.stop="deleteHandle('thesis', index)">
											<Icon type="ios-trash" />
										</span>
									</div>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.name"
													:disabled="entry === 'view'"
													placeholder="论文题目/专著名称"
												/>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.publishouse"
													:disabled="entry === 'view'"
													placeholder="发表杂志/专著出版社"
												/>
											</FormItem>
										</Col>
									</Row>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.issueNum"
													:disabled="entry === 'view'"
													placeholder="期刊号"
												/>
											</FormItem>
										</Col>
									</Row>
								</div>
							</draggable>
						</div>
						<!-- 附件资料 -->
						<div class="module">
							<h3 class="title">
								<Icon type="md-folder" />
								附件资料
								<Button
									icon="md-add"
									v-if="entry !== 'view'"
									@click="addHandle('books')"
									>添加</Button
								>
							</h3>
							<draggable
								v-model="userCv.books"
								:disabled="entry === 'view'"
								:options="{ group: 'books' }"
							>
								<div
									class="item"
									v-for="(child, index) in userCv.books"
									:key="index"
									@click="editHandle('books', child)"
									@mouseover="editHandle('books', child)"
								>
									<div class="delete" v-if="entry !== 'view'">
										<span @click.stop="deleteHandle('books', index)">
											<Icon type="ios-trash" />
										</span>
									</div>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.certificateNo"
													:disabled="entry === 'view'"
													placeholder="证书编号"
												/>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.certificateName"
													:disabled="entry === 'view'"
													placeholder="证书名称"
												/>
											</FormItem>
										</Col>
									</Row>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<Input
													v-model="child.issueAgency"
													:disabled="entry === 'view'"
													placeholder="发证机构"
												/>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem class="print-item">
												<DatePicker
													type="date"
													style="width: 100%"
													:value="child.issueDate"
													format="yyyy-MM-dd"
													:disabled="entry === 'view'"
													@on-change="v => (child.issueDate = v)"
													placeholder="发证日期"
												>
												</DatePicker>
											</FormItem>
										</Col>
									</Row>
									<Row :gutter="32">
										<Col span="12">
											<FormItem class="print-item">
												<DatePicker
													type="date"
													style="width: 100%"
													:value="child.effectiveDate"
													format="yyyy-MM-dd"
													:disabled="entry === 'view'"
													@on-change="v => (child.effectiveDate = v)"
													placeholder="有效日期"
												>
												</DatePicker>
											</FormItem>
										</Col>
										<Col span="24">
											<CtmsUpload
												ref="upAttach"
												:class="{ noDelBtn: entry === 'view' }"
												:disabled="entry === 'view'"
												:headers="{ token: $store.state.user.token }"
												:action="`${$baseUrl}/auth/self-info/cv-file`"
												accept="image/jpe,image/jpg,image/jpeg,image/png,
                    image/gif,image/tiff,application/pdf"
												:defaultFileList="child.defaultList"
												:uploadList="child.defaultList"
												@removeFile="removeFile"
												@updateList="getFileList"
											>
											</CtmsUpload>
										</Col>
									</Row>
								</div>
							</draggable>
						</div>
					</Form>
				</div>
			</div>
		</div>
		<!-- 添加 修改 培训记录 -->
		<Modal
			:title="trainTitle"
			:value="trainVisible"
			:mask-closable="false"
			@on-cancel="handleCancelTrain"
		>
			<Form
				:model="trainForm"
				ref="trainForm"
				label-position="top"
				:rules="trainRules"
			>
				<FormItem label="培训课题" prop="title">
					<Input
						v-model="trainForm.title"
						clearable
						placeholder="培训课题"
					></Input>
				</FormItem>
				<FormItem label="培训时间" prop="signedTime">
					<DatePicker
						type="daterange"
						style="width: 100%"
						:clearable="true"
						:value="trainForm.signedTime"
						format="yyyy/MM/dd"
						@on-change="v => (trainForm.signedTime = v)"
						placeholder="培训时间"
					>
					</DatePicker>
				</FormItem>
				<FormItem label="培训内容" prop="content">
					<Input
						v-model="trainForm.content"
						type="textarea"
						placeholder="培训内容"
					></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="handleCancelTrain">取 消</Button>
				<Button type="primary" :loading="loading" @click="handleAddTrainOk"
					>确 定</Button
				>
			</div>
		</Modal>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:jobList="dictionaryList[7]"
			:sexList="dictionaryList[5]"
			:id="userId"
			:type="'personalInfo'"
		></Add>
		<!--头像上传-->
		<UploadProfilePicture
			:visible="profilePictureVisible"
			@onCancel="profilePictureVisible = false"
			@onOk="handleUploadOk"
		></UploadProfilePicture>
		<!--修改密码-->
		<!--<Password :visible="changePasswordVisible"-->
		<!--@onCancel="changePasswordVisible = false"></Password>-->
	</div>
</template>

<script>
import "./style.less"
import { mapState } from "vuex"
import draggable from "vuedraggable"
import Add from "@/views/Setting/Staff/Add.vue"
import UploadProfilePicture from "./UploadProfilePicture.vue"
import publicApi from "@/api/public"
import api from "@/api/personalInfo/resume"
import defaultImg from "@/assets/common/defaultAvatar.png"
import Password from "./Password.vue"
import { printResume, formDataToMD5 } from "@/utils/util"

const { publicGetDictionaryList } = publicApi
const {
	apiGetPage,
	apiUpdate,
	uploadFile,
	apiAddTrain,
	apiEditTrain,
	apiDelTrain,
	getTrainHistory,
	apiGetResume,
	apiGetTrain
} = api
export default {
	components: {
		Add,
		draggable,
		UploadProfilePicture,
		Password
	},
	props: {
		entry: {
			type: String,
			default: "edit"
		},
		id: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			defaultImg,
			trainTitle: "新增个人培训记录",
			trainId: "",
			trainForm: {
				title: "",
				content: "",
				signedTime: []
			},
			trainRules: {
				title: {
					required: true,
					message: "培训课题不能为空",
					trigger: "blur"
				},
				signedTime: {
					required: true,
					message: "培训时间不能为空",
					type: "array",
					fields: {
						0: { required: true, message: "请选择开始时间" },
						1: { required: true, message: "请选择结束时间" }
					}
				}
			},
			UpVisible: false,
			trainVisible: false,
			loading: false,
			addVisible: false,
			profilePictureVisible: false,
			dictionaryList: [],
			userId: this.$store.state.user.info.id,
			userInfo: this.$store.state.user,
			userCv: {
				edu: [],
				gcp: "1",
				remark: [],
				temping: [],
				thesis: [],
				works: [],
				clinical: [],
				books: [],
				award: [],
				training: []
			},
			trainingRecord: [],
			rules: {
				gcp: {
					required: true,
					message: "请选择是否接受过GCP培训",
					trigger: "change"
				}
			},
			editObj: {},
			time: false,
			changePasswordVisible: false,
			formData: {
				identityBack: "",
				identityFront: "",
				businessProxy: ""
			},
			newColumns: [],
			columns: [
				{
					title: "培训课题",
					key: "trainingTitle",
					minWidth: 100
				},
				{
					title: "培训内容",
					key: "trainingContent",
					minWidth: 100
				},
				{
					title: "培训时间",
					key: "time",
					minWidth: 100,
					render: (h, params) =>
						h(
							"span",
							{},
							`${params.row.startDt.slice(0, 10)} ~ ${params.row.endDt.slice(0, 10)}`
						)
				},
				{
					title: "操作",
					key: "action",
					width: 130,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{
								label: "修改",
								on: {
									click: this.handleUpdateTrain
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleDeleteTrain
								},
								confirm: {
									title: "您确认删除这条培训记录吗？"
								}
							}
						]
						// trainType： 1：医院培训（在培训模块和个人简历中都显示）2：个人培训（培训模块内不显示，只在个人简历中显示
						if (Number(params.row.trainType) === 1) {
							actionList[0] = null
							actionList[1] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			listData: [],
			avatar: ""
		}
	},
	computed: {
		...mapState("user", {
			userAvatar: state => (state.avatar === "undefined" ? "" : state.avatar)
		})
	},
	created() {
		this.newColumns = [...this.columns]
		if (this.entry === "view") {
			this.newColumns.pop()
		}
		this.initResume()
		this.getDicList()
		this.getTrainHistory()
	},
	methods: {
		initResume() {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				if (this.entry === "view") {
					res = await apiGetResume(this.id)
				} else {
					res = await apiGetPage()
				}
				if (res) {
					this.userInfo = res.data.user
					if (this.entry === "view") {
						this.avatar = this.userInfo.photo
							? `${this.$baseUrl}/document/attachment/photo/${this.userInfo.photo}?token=${this.$store.state.user.token}`
							: ""
					} else {
						this.avatar = this.userAvatar
					}
					Object.assign(this.formData, {
						identityBack: res.data.user.identityBack,
						identityFront: res.data.user.identityFront,
						businessProxy: res.data.user.businessProxy
					})
					Object.keys(this.userCv).forEach(k => {
						if (res.data.userCV[k]) {
							this.userCv[k] = JSON.parse(res.data.userCV[k])
						}
					})
					this.userCv.gcp = res.data.userCV.gcp
						? String(res.data.userCV.gcp)
						: "1"
				}
				this.loading = false
			})
		},
		getDicList() {
			this.$asyncDo(async () => {
				const res = await publicGetDictionaryList()
				if (res) {
					this.dictionaryList = res.data
				}
			})
		},
		async getTrainHistory() {
			let res = false
			if (this.entry === "view") {
				res = await apiGetTrain(this.id)
			} else {
				res = await getTrainHistory()
			}
			if (res) {
				this.listData = res.data || []
			}
		},
		handleAddTrain() {
			// 添加 培训记录
			this.trainForm = {
				title: "",
				content: "",
				signedTime: []
			}
			this.$refs.trainForm.resetFields()
			this.trainTitle = "新增个人培训记录"
			this.trainId = ""
			this.trainVisible = true
		},
		handleUpdateTrain({ row }) {
			// 修改 培训记录
			this.trainTitle = "修改个人培训记录"
			this.trainForm = {
				title: row.trainingTitle,
				content: row.trainingContent,
				signedTime: [row.startDt, row.endDt]
			}
			this.trainId = row.trainingId
			this.trainVisible = true
		},
		handleCancelTrain() {
			this.trainVisible = false
		},
		handleAddTrainOk() {
			// 添加 修改 培训记录 提交
			this.$refs.trainForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						const params = {
							title: this.trainForm.title,
							content: this.trainForm.content,
							startTime: this.trainForm.signedTime[0],
							endTime: this.trainForm.signedTime[1]
						}
						if (this.trainId) {
							res = await apiEditTrain({
								...params,
								trainingId: this.trainId
							})
						} else {
							res = await apiAddTrain({
								...params
							})
						}
						if (res) {
							this.$Message.success("操作成功")
							this.trainVisible = false
							this.getTrainHistory()
						}
						this.loading = false
					})
				}
			})
		},
		async handleDeleteTrain({ row }) {
			const res = await apiDelTrain(row.trainingId)
			if (res) {
				this.$Message.success("删除成功")
				this.getTrainHistory()
			}
		},
		signHandle() {
			this.UpVisible = true
		},
		// 编辑基本信息
		editBaseInfo() {
			this.addVisible = true
		},
		// 编辑基本信息成功
		handleAddOk() {
			this.addVisible = false
			this.initResume()
		},
		handleUploadOk() {
			this.profilePictureVisible = false
			this.$store.dispatch("user/getInfo")
		},
		// 打印简历
		printPDF() {
			const gcp = this.userCv.gcp === "1" ? "否" : "是"
			printResume(document.getElementById("printPdf"), gcp)
		},
		getFileList(obj) {
			Object.assign(this.editObj, {
				defaultList: obj
			})
		},
		// 新增
		addHandle(type) {
			const userCvType = this.userCv[type]
			switch (type) {
				case "edu":
					userCvType.push({
						time: "",
						institution: "",
						degree: ""
					})
					break
				case "works":
					userCvType.push({
						time: "",
						organization: "",
						position: ""
					})
					break
				case "clinical":
					userCvType.push({
						time: "",
						indication: "",
						stage: "",
						responsibily: ""
					})
					break
				case "temping":
					userCvType.push({
						name: "",
						position: ""
					})
					break
				case "award":
					userCvType.push({
						questionNum: "",
						date: "",
						source: "",
						name: ""
					})
					break
				case "training":
					userCvType.push({
						organizers: "",
						date: "",
						name: ""
					})
					break
				case "trainingRecord":
					this.trainingRecord.push({
						trainingTitle: "",
						trainingContent: "",
						time: ""
					})
					break
				case "thesis":
					userCvType.push({
						name: "",
						publishouse: "",
						issueNum: ""
					})
					break
				case "books":
					userCvType.push({
						certificateName: "",
						certificateNo: "",
						issueDate: "",
						effectiveDate: "",
						fileId: "",
						defaultList: []
					})
					break
				default:
					break
			}
		},
		// 编辑
		editHandle(type, item) {
			this.editObj = item
		},
		// 删除
		deleteHandle(type, index) {
			this.userCv[type].splice(index, 1)
		},
		removeFile(obj) {
			this.editObj.defaultList = this.editObj.defaultList.filter(
				o => o.id !== obj.id
			)
		},
		// 保存
		saveHandle() {
			this.$refs.userCVForm.validate(valid => {
				if (valid) {
					const keys = Object.keys(this.userCv)
					const datas = {}
					keys.forEach(k => {
						if (this.userCv[k].length) {
							datas[k] = this.userCv[k]
						}
					})
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiUpdate({ ...datas, ...this.formData })
						if (res) {
							this.$Message.success("修改成功")
							this.userCv.trainingRecord = []
							this.initResume()
						}
						this.loading = false
					})
				}
			})
		},
		// md5
		getMD5(file, type) {
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, { md5: md5Str }, type)
			})
			return false
		},
		// 上传文件
		uploadFile(file, params, type) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				const res = await uploadFile(fd, params)
				if (res) {
					this.$Message.success("上传成功!")
					this.formData[type] = res.data
				}
			})
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		}
	}
}
</script>

<style lang="less">
.resume-wrap {
	.noDelBtn {
		.ivu-upload-list-remove {
			display: none;
		}
	}
}

.gray-font {
	&::placeholder {
		color: #ccc;
	}
}
</style>
