var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e(),_vm._l((_vm.listdata),function(list,index){return _c('div',{key:index},[_c('p',{staticClass:"role-title"},[_vm._v(_vm._s(list.roleName)+"("+_vm._s(list.userList.length)+")")]),_c('div',{staticClass:"user-list"},[_vm._l((list.userList),function(user,idx){return _c('div',{key:idx,staticClass:"user-item item"},[_c('img',{attrs:{"src":_vm.defaultAvatar,"alt":""},on:{"click":function($event){return _vm.handleViewShow(user)}}}),_c('div',{staticClass:"user-info"},[_c('p',{attrs:{"title":user.name},on:{"click":function($event){return _vm.handleViewShow(user)}}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(user.name)+"\n\t\t\t\t\t")]),_c('div',[_c('Button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.handleViewShow(user)}}},[_vm._v("查看")]),(
								!(
									_vm.projectActionPermissions.indexOf('btn_project_team_add') ===
									-1
								)
							)?_c('Poptip',{attrs:{"confirm":"","placement":"top","title":"确定删除该成员吗？","transfer":true},on:{"on-ok":function($event){return _vm.deleteHandle(user, list.roleId)}}},[_c('Button',{attrs:{"type":"error","size":"small"}},[_vm._v("删除")])],1):_vm._e()],1)])])}),(
					!(_vm.projectActionPermissions.indexOf('btn_project_team_add') === -1)
				)?_c('div',{staticClass:"item add-user",on:{"click":function($event){return _vm.handleAddShow(list)}}},[_c('div',{staticClass:"add"},[_c('p',[_c('Icon',{attrs:{"type":"md-add"}})],1),_c('p',[_vm._v("添加成员")])])]):_vm._e()],2)])}),_c('Add',{attrs:{"visible":_vm.addVisible,"role":_vm.role},on:{"onCancel":function($event){_vm.addVisible = false},"onOk":_vm.handleAddOk}}),_c('ViewDetail',{attrs:{"visible":_vm.viewVisible,"id":_vm.viewId},on:{"onCancel":function($event){_vm.viewVisible = false}}})],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }