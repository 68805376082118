<template>
	<div class="content-wrap">
		<!--IstReport报表-->
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
      <CtmsSearchBoxItem :span="1" label="项目名称">
      <Input
        v-model="searchParams.projectName"
        placeholder="项目名称"
      ></Input>
    </CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="创建日期">
				<DatePicker
					type="daterange"
					placeholder="请选择"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
      <CtmsSearchBoxItem :span="1" label="项目阶段">
        <Select
          v-model="searchParams.type"
          :transfer="true"
          placeholder="请选择类型"
        >
          <Option :value="1">入账</Option>
          <Option :value="2">出账</Option>
        </Select>
      </CtmsSearchBoxItem>
      <CtmsSearchBoxItem :span="1" label="研究型病房项目">
        <Select
          v-model="searchParams.type"
          :transfer="true"
          placeholder="请选择类型"
        >
          <Option :value="1">入账</Option>
          <Option :value="2">出账</Option>
        </Select>
      </CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button @click="exportData">导出IST报表</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="data"
      :page="page"
			ref="table"
		>
			<template slot-scope="{ row }" slot="information">
				<span>
					{{
						row.information
							? inAccountInfo.find(item => item.value === row.information).label
							: ""
					}}
				</span>
			</template>
		</CtmsDataGrid>
	</div>
</template>

<script>
import fundsApi from "@/api/project/funds"
import { mapState } from "vuex"

const { apiGetDeparts, apiGetReportFundsListPage, apiGetReportFundsDetail } =
	fundsApi

export default {
	name: "IstReport",
	data() {
		return {
			loading: false,
			entry: "",
			updateId: "",
			fundsType: "", // 1 入账 / 2 出账
			// 科室
			departList: [],
			// 项目基本信息
			projectInfo: {},
			times: [],
			searchParams: {
        projectName: "",
				approvedStatus: "",
				type: "",
				arrivalTimeStart: this.times ? this.times[0] : "",
				arrivalTimeEnd: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
        projectName: "",
				approvedStatus: "",
				type: "",
				arrivalTimeStart: this.times ? this.times[0] : "",
				arrivalTimeEnd: this.times ? this.times[1] : ""
			},
			data: [
        {"a":"评价宫腔防粘连水凝胶的有效性和安全性的前瞻性、多中心、随机、平行对照、非劣效性临床试验","b":"立项准备阶段","c":"合同签署","d":"","e":"2024/10/14","f":"2024-FPD-04-08","g":"","h":"","i":"","j":"2024/10/21","k":"","l":"","m":"","n":"","o":"","p":"","q":"待定稿","r":42.5075,"s":"","t":"","u":"","v":"","w":"","x":"","y":"","z":"","aa":"","ab":""},
      ],
			columns: [
				{
					title: "项目名称",
					key: "a",
					minWidth: 250
				},
				{
					title: "项目所处大阶段",
					key: "b",
					minWidth: 150
				},
				{
					title: "项目具体阶段",
					key: "c",
					minWidth: 140
				},
				{
					title: "申办方首次调研对接机构日期",
					key: "d",
					minWidth: 140
				},
				{
					title: "机构立项日期",
					key: "e",
					minWidth: 140
				},
				{
					title: "机构受理号/立项号",
					key: "f",
					minWidth: 160
				},
				{
					title: "伦理受理号",
					key: "g",
					minWidth: 120
				},
				{
					title: "伦理受理日期",
					key: "h",
					minWidth: 120
				},
				{
					title: "首次伦理批件号",
					key: "i",
					minWidth: 140
				},
				{
					title: "首次伦理批件日期",
					key: "j",
					minWidth: 150
				},
				{
					title: "是否办理人类遗传办批件",
					key: "k",
					minWidth: 140
				},
				{
					title: "人类遗传办批件申请事项",
					key: "l",
					minWidth: 140
				},
				{
					title: "是否办理人类遗传办批件申请类别",
					key: "m",
					minWidth: 150
				},
				{
					title: "是否办理人类遗传办批件-通知时间",
					key: "n",
					minWidth: 150
				},
				{
					title: "人遗审批/备案日期",
					key: "o",
					minWidth: 120
				},
				{
					title: "人遗批件号",
					key: "p",
					minWidth: 120
				},
				{
					title: "合同签署日期",
					key: "q",
					minWidth: 120
				},
				{
					title: "合同金额（万元）",
					key: "r",
					minWidth: 120
				},
				{
					title: "首笔款到账金额",
					key: "s",
					minWidth: 140
				},
				{
					title: "首笔款到账日期",
					key: "t",
					minWidth: 140
				},
				{
					title: "累积已到账金额",
					key: "u",
					minWidth: 140
				},
				{
					title: "项目启动日期",
					key: "v",
					minWidth: 120
				},
        {
          title: "本机构第一例受试者签署知情同意书的日期",
          key: "w",
          minWidth: 180
        },
        {
          title: "本机构第一例受试者入组的日期",
          key: "x",
          minWidth: 140
        },
        {
          title: "试验终止日期",
          key: "y",
          minWidth: 120
        },
        {
          title: "招募状态",
          key: "z",
          minWidth: 140
        },
        {
          title: "已筛选例数",
          key: "aa",
          minWidth: 120
        },
        {
          title: "已入组",
          key: "ab",
          minWidth: 120
        }
			],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 140
		})
	},
	mounted() {
		this.getPublicData()
		// this.initData()
	},
	methods: {
		getPublicData() {
			// 获取科室
			this.$asyncDo(async () => {
				const res = await apiGetDeparts()
				if (res) {
					this.departList = res.data || []
				}
			})
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetReportFundsListPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					classification: 1
				})
				if (res) {
					this.data = res.data.list || []
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		exportData() {
			/*
			 * classification 费用分类：1、机构 2、伦理
			 * type 费用类型：1、入账 2、出账
			 * arrivalTimeStart 入/出账时间-开始
			 * arrivalTimeEnd 入/出账时间-结束
			 * */
			const projectName = this.searchParams.projectName || ""
			const type = this.searchParams.type || ""
			const start = this.searchParams.arrivalTimeStart || ""
			const end = this.searchParams.arrivalTimeEnd || ""
			const approvedStatus =
				this.searchParams.approvedStatus === undefined
					? ""
					: this.searchParams.approvedStatus
			const url = `${this.$baseUrl}/report/outlay/export?classification=1&type=${type}&arrivalTimeStart=${start}&arrivalTimeEnd=${end}&approvedStatus=${approvedStatus}&token=${this.$store.state.user.token}`
			window.open(url)
		},
		// 重置
		reset() {
			this.times = []
			this.searchParams.type = ""
			this.searchParams.approvedStatus = ""
			this.searchHandle()
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		},
		searchHandle() {
			this.searchParams.arrivalTimeStart = this.times.length
				? this.times[0]
				: ""
			this.searchParams.arrivalTimeEnd = this.times.length ? this.times[1] : ""
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initData()
			})
		}
	}
}
</script>
