import CtmsSearchBoxItem from "./CtmsSearchBox/CtmsSearchBoxItem.vue"
import CtmsSearchBox from "./CtmsSearchBox/CtmsSearchBox.vue"
import CtmsDataGrid from "./CtmsDataGrid/CtmsDataGrid.vue"
import CtmsAction from "./CtmsAction/CtmsAction.vue"
import CtmsEditor from "./CtmsEditor/CtmsEditor.vue"
import CtmsUpload from "./CtmsUpload/CtmsUpload.vue"
import CtmsTinyEditor from "./CtmsTinyEditor/CtmsTinyEditor.vue"
import CtmsIcon from "./CtmsIcon/CtmsIcon.vue"
import CtmsCalendar from "./CtmsCalendar/CtmsCalendar.vue"
import CtmsUploadProgress from "./CtmsUploadProgress/CtmsUploadProgress.vue"

const ctmsComponents = {
	CtmsSearchBox,
	CtmsSearchBoxItem,
	CtmsDataGrid,
	CtmsAction,
	CtmsEditor,
	CtmsUpload,
	CtmsTinyEditor,
	CtmsIcon,
	CtmsCalendar,
	CtmsUploadProgress
}

const install = Vue => {
	if (install.installed) return
	Object.keys(ctmsComponents).forEach(key => {
		Vue.component(key, ctmsComponents[key])
	})
}

// auto install
if (typeof window !== "undefined" && window.Vue) {
	install(window.Vue)
}

const CtmsComponents = {
	install,
	...ctmsComponents
}

export default CtmsComponents
