<template>
	<Modal
		:title="title"
		:value="visible"
		width="900"
		:mask-closable="false"
		@on-cancel="onCancel"
		class="trainCert-drawer-wrapper"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			:model="formData"
			ref="certForm"
			label-position="top"
			:rules="rulesValid"
		>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="持证人员" prop="certificateUserId">
						<Select
							style="width: 220px"
							v-model="formData.certificateUserId"
							filterable
							clearable
							placeholder="请选择持证人员"
						>
							<Option
								:value="item.id"
								v-for="item in userList"
								:key="item.id"
								>{{ item.username }}</Option
							>
						</Select>
						<Button style="margin-left: 10px" @click="handleAddUser"
							>添加人员</Button
						>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="培训项目" prop="trainProject">
						<Input
							v-model="formData.trainProject"
							:maxlength="50"
							type="textarea"
							placeholder="请输入培训项目"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="证书类别" prop="certificateType">
						<Select
							v-model="formData.certificateType"
							:transfer="true"
							clearable
							placeholder="请选择证书类别"
						>
							<Option
								:value="item.id"
								v-for="item in certificateList"
								:key="item.id"
								>{{ item.name }}</Option
							>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="培训时间" prop="trainDates">
						<DatePicker
							type="daterange"
							placeholder="请选择培训时间"
							:clearable="false"
							style="width: 100%"
							:value="formData.trainDates"
							format="yyyy-MM-dd"
							@on-change="handleTrainDatesChange"
						></DatePicker>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="培训方式" prop="trainType">
						<Select v-model="formData.trainType" clearable placeholder="请选择">
							<Option
								:value="item.id"
								v-for="item in trainTypes"
								:key="item.id"
								>{{ item.name }}</Option
							>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="培训学时" prop="trainHours">
						<InputNumber
							:min="0"
							:precision="0"
							:max="999999999"
							placeholder="请输入培训学时"
							style="width: 100%"
							v-model="formData.trainHours"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="发证日期" prop="awardDate">
						<DatePicker
							type="date"
							placeholder="请选择"
							format="yyyy-MM-dd"
							v-model="formData.awardDate"
							style="width: 100%"
						></DatePicker>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="培训机构">
						<Input
							v-model="formData.trainInstitution"
							:maxlength="50"
							type="textarea"
							placeholder="请输入培训机构"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="证书编号">
						<Input
							v-model="formData.certificateNo"
							:maxlength="30"
							placeholder="请输入证书编号"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="是否考核" prop="assessmentFlag">
						<Select v-model="formData.assessmentFlag" placeholder="请选择">
							<Option value="是">是</Option>
							<Option value="否">否</Option>
						</Select>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
		<AddUser
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddUserOk"
		>
		</AddUser>
	</Modal>
</template>

<script>
import api from "@/api/cert/cert"
import AddUser from "./AddUser.vue"
import moment from "moment"

const { editCert, addCert, apiGetUserData } = api

export default {
	name: "Add",
	components: {
		AddUser
	},
	props: ["visible", "id", "certificateList", "trainTypes", "currentRow"],
	data() {
		return {
			loading: false,
			addVisible: false,
			userList: [],
			formData: {
				trainDates: [],
				trainHours: null
			},
			title: "",
			rulesValid: {
				certificateUserId: [
					{
						required: true,
						type: "number",
						message: "持证人员不能为空",
						trigger: "change"
					}
				],
				certificateType: {
					required: true,
					message: "证书类别不能为空",
					trigger: "change"
				},
				trainProject: {
					required: true,
					message: "培训项目不能为空",
					trigger: "blur"
				},
				assessmentFlag: {
					required: true,
					message: "是否考核不能为空",
					trigger: "change"
				},
				trainType: {
					required: true,
					message: "培训方式不能为空",
					trigger: "change"
				},
				awardDate: [
					{
						type: "date",
						required: true,
						message: "发证日期不能为空",
						trigger: "change"
					}
				],
				trainHours: [
					{
						required: true,
						type: "number",
						message: "培训学时不能为空",
						trigger: "change blur"
					}
				],
				trainDates: [
					{
						required: true,
						message: "培训时间不能为空",
						type: "array",
						trigger: "change"
					}
				]
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.certForm.resetFields()
				this.getUserData()
				this.formData = {
					trainDates: [],
					trainHours: null
				}
				this.title = "新增证书"
				if (this.id) {
					this.title = "修改证书"
					const { trainStartDate, trainEndDate, certificateType, trainType } =
						this.currentRow
					this.$nextTick(() => {
						this.formData = {
							...this.currentRow,
							certificateType: String(certificateType),
							trainType: String(trainType),
							trainDates: trainStartDate ? [trainStartDate, trainEndDate] : [],
							awardDate: this.currentRow.awardDate
								? new Date(this.currentRow.awardDate)
								: ""
						}
					})
				}
			}
		}
	},
	methods: {
		// 添加人员
		handleAddUser() {
			this.addVisible = true
		},
		// 新增持证人员成功后，刷新持证人员下拉列表
		handleAddUserOk() {
			this.addVisible = false
			this.getUserData()
		},
		// 持证人员下拉列表
		getUserData() {
			this.$asyncDo(async () => {
				const res = await apiGetUserData()
				if (res) {
					this.userList = res.data || []
				}
			})
		},
		handleTrainDatesChange(value) {
			this.$set(this.formData, "trainDates", value)
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.certForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						const { trainDates, awardDate } = this.formData
						const params = {
							...this.formData,
							trainStartDate:
								trainDates && trainDates.length ? trainDates[0] : "",
							trainEndDate:
								trainDates && trainDates.length ? trainDates[1] : "",
							awardDate: awardDate ? moment(awardDate).format("YYYY-MM-DD") : ""
						}
						if (this.id) {
							res = await editCert(params)
						} else {
							res = await addCert(params)
						}
						if (res) {
							this.$emit("onOk")
							this.$emit("onCancel")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
			return true
		}
	}
}
</script>

<style lang="less">
.trainCert-drawer-wrapper {
	.warning {
		color: #ed4014;
		font-size: 12px;
		margin-bottom: 10px;
	}
	.date-disabled {
		.ivu-input[disabled] {
			color: #515a6e;
		}
	}
	.warn-disabled {
		.ivu-input[disabled] {
			color: #ed4014;
		}
	}
}
</style>
