<template>
	<div>
		<div class="status-desc" :data-status="status">
			<div class="dot"></div>
			<div>{{ status | getStatus }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Status",
	props: {
		status: {
			type: [String, Number],
			default() {
				return ""
			}
		}
	},
	filters: {
		getStatus(val) {
			// 1：信息公开，2：通知公告，3：临床试验信息公告
			let statusObj = {
				1: "信息公开",
				2: "通知公告",
				2: "临床试验信息公告"
			}
			return statusObj[val]
		}
	}
}
</script>

<style lang="less" scoped>
.status-desc {
	display: flex;
	margin-right: 0;
	align-items: center;
	color: #333;
	justify-content: flex-end;
	.dot {
		width: 5px;
		height: 5px;
		margin-right: 8px;
		border-radius: 50%;
		background-color: #fff;
	}
	&[data-status="1"] {
		.dot {
			background-color: #2d8cf0;
		}
	}
	&[data-status="2"] {
		.dot {
			background-color: #28bc5c;
		}
	}
	&[data-status="3"] {
		.dot {
			background-color: #f56c6c;
		}
	}
}
</style>
