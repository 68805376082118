<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="表单名称">
				<Input
					v-model="searchParams.nameLike"
					clearable
					placeholder="模糊查询"
				/>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="是否发布">
				<Select
					v-model="searchParams.publish"
					clearable
					:transfer="true"
					placeholder="请选择是否发布"
				>
					<Option :value="1">发布</Option>
					<Option :value="0">未发布</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="状态">
				<Select
					v-model="searchParams.isDel"
					clearable
					:transfer="true"
					placeholder="请选择状态"
				>
					<Option :value="0">启用</Option>
					<Option :value="1">禁用</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				@click="handleAddShow"
				v-if="!(systemActionPermissions.indexOf('btn_setting_form_add') === -1)"
				>新增</Button
			>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="false"
		></CtmsDataGrid>
		<!--表单新增 修改 复制-->
		<Add
			:visible="addVisible"
			:isCopy="isCopy"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:id="updateId"
		></Add>
		<!--表单设计-->
		<Design
			:visible="designVisible"
			@onCancel="designVisible = false"
			:id="designId"
		></Design>
		<!--预览-->
		<Preview
			:visible="previewVisible"
			@onCancel="previewVisible = false"
			:id="previewId"
		></Preview>
		<BindFile
			:visible="bindVisible"
			:fileList="fileList"
			:bindObj="bindObj"
			@onCancel="bindVisible = false"
			@onOk="bindOk"
		>
		</BindFile>
	</div>
</template>

<script>
import api from "@/api/setting/form"
import Add from "./Add.vue"
import Preview from "./Preview.vue"
import Design from "./Design.vue"
import BindFile from "./BindFile.vue"
import { mapState } from "vuex"

const { apiGetPage, apiDelete, apiPublish, apiBindTypes, getFormFileList } = api

export default {
	name: "Form",
	components: {
		Add,
		Preview,
		Design,
		BindFile
	},
	data() {
		return {
			listData: [],
			columns: [
				{
					title: "表单名称",
					key: "name",
					minWidth: 250
				},
				{
					title: "是否发布",
					key: "publish",
					render: (h, params) =>
						h("span", {}, params.row.publish ? "已发布" : "未发布"),
					minWidth: 120
				},
				{
					title: "状态",
					key: "isDel",
					render: (h, params) =>
						h("span", {}, params.row.isDel ? "禁用" : "启用"),
					minWidth: 100
				},
				{
					title: "描述",
					key: "descr",
					minWidth: 150
				},
				{
					title: "操作",
					key: "action",
					width: 370,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{
								label: "修改",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "设计",
								on: {
									click: this.handleDesignShow
								}
							},
							{
								label: "复制",
								on: {
									click: this.handleCopyShow
								}
							},
							{
								label: "绑定文件",
								on: {
									click: this.bindFile
								}
							},
							{
								label: "预览",
								on: {
									click: this.handlePreviewShow
								}
							},
							{
								label: "发布",
								on: {
									click: this.handleSwitchPublishState
								},
								confirm: {
									title: "您确认发布此表单吗？"
								}
							},
							{
								label: params.row.isDel ? "启用" : "禁用",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: `您确认${params.row.isDel ? "启用" : "禁用"}这条数据吗？`
								}
							}
						]
						if (params.row.publish) {
							// 发布之后还是可以修改名字和描述的
							// actionList[0] = null;
							actionList[5] = null
						}
						if (
							this.systemActionPermissions.indexOf("btn_setting_form_add") ===
							-1
						) {
							actionList[0] = null
							actionList[1] = null
							actionList[2] = null
							actionList[3] = null
							actionList[5] = null
							actionList[6] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			loading: false,
			addVisible: false,
			viewVisible: false,
			bindVisible: false,
			updateId: "",
			viewId: "",
			designVisible: false,
			designId: "",
			previewVisible: false,
			previewId: "",
			bindObj: {},
			isCopy: "", // 标识是否是复制
			searchParams: {
				publish: "",
				isDel: 0,
				nameLike: ""
			},
			oldSearchParams: {
				publish: "",
				isDel: 0,
				nameLike: ""
			},
			fileList: []
			// bindTypes: [],
			// showType: 'design',
		}
	},
	created() {
		// this.getBaseData();
		this.initList()
		this.getFileList()
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	methods: {
		// 获取子流程文件列表
		async getFileList() {
			this.loading = true
			const res = await getFormFileList({
				isDisable: 0
			})
			this.fileList = res.data || []
			this.loading = false
		},
		// 初始化列表
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					...this.oldSearchParams,
					pageNum: 1,
					pageSize: 999
				})
				if (res) {
					this.listData = res.data.list
					// this.page.total = res.data.total;
				}
				this.loading = false
			})
		},
		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
			this.isCopy = false
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 绑定子流程文件成功
		bindOk() {
			this.bindVisible = false
			this.initList()
		},
		// 查看显示
		handleViewShow({ row }) {
			this.viewVisible = true
			this.viewId = row.id
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.addVisible = true
			this.updateId = row.id
			this.isCopy = false
		},
		// 复制显示
		handleCopyShow({ row }) {
			this.addVisible = true
			this.updateId = row.id
			this.isCopy = true
		},
		// 绑定文件
		bindFile({ row }) {
			this.bindObj = row
			this.bindVisible = true
		},
		// 设计表单显示
		handleDesignShow({ row }) {
			this.designVisible = true
			this.designId = row.id
		},
		// 预览显示
		handlePreviewShow({ row }) {
			this.previewVisible = true
			this.previewId = row.id
		},
		// 删除
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiDelete({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 发布
		handleSwitchPublishState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiPublish({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				publish: "",
				isDel: 0,
				nameLike: ""
			}
			// this.page.current = 1;
			this.$nextTick(() => {
				this.searchHandle()
			})
		},
		// 搜索
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>
