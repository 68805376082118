<template>
	<div class="content-wrap instrument-wrapper">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="证书类别">
				<Select
					v-model="searchParams.certificateType"
					:transfer="true"
					clearable
					placeholder="请选择证书类别"
				>
					<Option
						:value="item.id"
						v-for="item in certificateList"
						:key="item.id"
						>{{ item.name }}</Option
					>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="发证日期">
				<DatePicker
					type="daterange"
					placeholder="请选择日期区间"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="姓名">
				<Input v-model="searchParams.username" placeholder="模糊查询"></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="证书编号">
				<Input
					v-model="searchParams.certificateNo"
					placeholder="模糊查询"
				></Input>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				v-if="systemActionPermissions.indexOf('btn_certificate_add') > -1"
				@click="handleAddShow"
			>
				新增
			</Button>
			<!--<Upload v-if="systemActionPermissions.indexOf('btn_ethic_certificate_add')>-1"-->
			<!--ref="upload"-->
			<!--:headers="{token:$store.state.user.token}"-->
			<!--:show-upload-list="false"-->
			<!--accept=".xlsx,.xls"-->
			<!--:before-upload="beforeUpload"-->
			<!--:on-success="onSuccess"-->
			<!--:on-error="onError"-->
			<!--:action="`${$baseUrl}/ethic-certificate/import`"-->
			<!--style="display:inline-block">-->
			<!--&lt;!&ndash;:data="extraData"&ndash;&gt;-->
			<!--<Button>导入</Button>-->
			<!--</Upload>-->
			<Button
				v-if="systemActionPermissions.indexOf('btn_certificate_export') > -1"
				@click="exportExcel"
				>导出</Button
			>
			<!--<Button @click="handleDownloadTemplate">下载导入模板</Button>-->
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
			ref="table"
		>
		</CtmsDataGrid>
		<Add
			:visible="addVisible"
			:currentRow="currentRow"
			:certificateList="certificateList"
			:trainTypes="trainTypes"
			@onCancel="addVisible = false"
			@onOk="initList"
			:id="updateId"
		>
		</Add>
	</div>
</template>
<script>
import { mapState } from "vuex"
import moment from "moment"
import Add from "./Add.vue"
import api from "@/api/cert/cert"
import dictionaryApi from "@/api/setting/dictionary"
import { formDataToMD5 } from "@/utils/util"

const { getCertListPage, delCert } = api
const { apiGetData } = dictionaryApi
export default {
	components: {
		Add
	},
	data() {
		return {
			loading: false,
			importLoading: false,
			certificateList: [],
			// 培训方式
			trainTypes: [],
			times: [],
			searchParams: {
				certificateType: "",
				username: "",
				certificateNo: "",
				awardStartDate: this.times ? this.times[0] : "",
				awardEndDate: this.times ? this.times[1] : ""
			},
			oldSearchParams: {},
			extraData: {
				md5: ""
			},
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			updateId: "",
			addVisible: false,
			listData: [],
			columns: [
				{
					title: "姓名",
					key: "username",
					minWidth: 120
				},
				{
					title: "职务",
					key: "position",
					minWidth: 120
				},
				{
					title: "职称",
					key: "jobTitle",
					minWidth: 120
				},
				{
					title: "证书类别",
					key: "certificateTypeText",
					minWidth: 120
				},
				{
					title: "培训时间",
					key: "trainDate",
					minWidth: 120
				},
				{
					title: "学时",
					key: "trainHours",
					minWidth: 120
				},
				{
					title: "培训机构",
					key: "trainInstitution",
					minWidth: 120
				},
				{
					title: "培训项目",
					key: "trainProject",
					minWidth: 120
				},
				{
					title: "培训方式",
					key: "trainTypeText",
					minWidth: 120
				},
				{
					title: "发证日期",
					key: "awardDate",
					minWidth: 120
				},
				{
					title: "证书编号",
					key: "certificateNo",
					minWidth: 120
				},
				{
					title: "是否考核",
					key: "assessmentFlag",
					minWidth: 120
				},
				{
					title: "操作",
					key: "action",
					width: 120,
					fixed: "right",
					renderButton: () => {
						const btnList = [
							{
								label: "编辑",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf("btn_certificate_update") ===
							-1
						) {
							btnList[0] = null
						}
						if (
							this.systemActionPermissions.indexOf("btn_certificate_delete") ===
							-1
						) {
							btnList[1] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			],
			currentRow: null
		}
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 130
			},
			...mapState("permission", ["systemActionPermissions"])
		})
	},
	created() {
		this.getPublicData()
	},
	mounted() {
		this.$nextTick(() => {
			this.initList()
		})
	},
	methods: {
		getPublicData() {
			this.$asyncDo(async () => {
				// 获取培训方式 26
				const res = await apiGetData("26")
				if (res) {
					const list = res.data ? res.data.leaf : []
					this.trainTypes = res.data.leaf.filter(item => item.obj.isDel === 0)
				}
				// 获取证书类别 20；
				const ret = await apiGetData("20")
				if (ret) {
					const list = ret.data ? ret.data.leaf : []
					this.certificateList = ret.data.leaf.filter(
						item => item.obj.isDel === 0
					)
					// this.certificateList = list.map(leaf => ({
					//   value: `${leaf.id}`,
					//   label: leaf.name,
					//   term: leaf.obj ? leaf.obj.nickname : '',
					// }));
				}
			})
		},
		// 获取数据
		async initList() {
			this.loading = true
			const res = await getCertListPage({
				...this.oldSearchParams,
				pageNum: this.page.current,
				pageSize: this.page.pageSize
			})
			if (res && res.data) {
				this.listData = res.data.list || []
				this.page.total = res.data.total
			}
			this.loading = false
		},
		// 文件上传前
		beforeUpload(file) {
			formDataToMD5(file, md5Str => {
				this.extraData.md5 = md5Str
				// 获取md5后进行文件上传
				this.$refs.upload.post(file)
			})
			return false
		},
		// 文件上传成功后
		onSuccess(res) {
			if (res.code !== 0) {
				this.$Message.error(res.message)
			} else {
				this.$Message.success("导入成功！")
				this.initList()
			}
		},
		onError(error) {
			this.$Message.error(error)
		},
		// 搜索
		searchHandle() {
			this.searchParams = {
				...this.searchParams,
				awardStartDate: this.times ? this.times[0] : "",
				awardEndDate: this.times ? this.times[1] : ""
			}
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.times = []
			this.searchParams = {
				certificateType: "",
				username: "",
				certificateNo: ""
			}
			this.searchHandle()
		},
		// 新增
		handleAddShow() {
			this.updateId = ""
			this.currentRow = null
			this.addVisible = true
		},
		// 修改
		handleUpdateShow({ row }) {
			this.updateId = row.id
			this.currentRow = { ...row }
			this.addVisible = true
		},
		// 删除
		async handleSwitchState({ row }) {
			this.loading = true
			const res = await delCert(row.id)
			if (res) {
				this.$Message.success("删除成功！")
				this.initList()
			}
			this.loading = false
		},
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 导出
		async exportExcel() {
			const type = this.searchParams.certificateType || ""
			const username = this.searchParams.username || ""
			const certificateNo = this.searchParams.certificateNo || ""
			const awardStartDate = this.searchParams.awardStartDate || ""
			const awardEndDate = this.searchParams.awardEndDate || ""
			const url = `${this.$baseUrl}/certificate/exportExcel?certificateType=${type}&username=${username}&certificateNo=${certificateNo}&awardStartDate=${awardStartDate}&awardEndDate=${awardEndDate}&token=${this.$store.state.user.token}`
			window.open(url)
		},
		// 下载证书模板
		handleDownloadTemplate() {
			// window.open(`${this.$baseUrl}/ethic-certificate/import-template-download?token=${this.$store.state.user.token}`);
		}
	}
}
</script>
