<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
<!--			<CtmsSearchBoxItem :span="1" label="所属组织">-->
<!--				<Select-->
<!--					v-model="searchParams.orgId"-->
<!--					:transfer="true"-->
<!--					placeholder="请选择所属组织"-->
<!--				>-->
<!--					<Option :value="list.id" v-for="list in orgLists" :key="list.id">{{-->
<!--						list.name-->
<!--					}}</Option>-->
<!--				</Select>-->
<!--			</CtmsSearchBoxItem>-->
<!--			<CtmsSearchBoxItem :span="1" label="状态">-->
<!--				<Select-->
<!--					v-model="searchParams.isDel"-->
<!--					:transfer="true"-->
<!--					clearable-->
<!--					placeholder="请选择状态"-->
<!--				>-->
<!--					<Option :value="0">启用</Option>-->
<!--					<Option :value="1">禁用</Option>-->
<!--				</Select>-->
<!--			</CtmsSearchBoxItem>-->
			<CtmsSearchBoxItem :span="1" label="部门/科室名称">
				<Input
					placeholder="请输入部门/科室名称"
					v-model="searchParams.queryLike"
				/>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
    <div class="custom-tree-container" :style="`height:${dataGridHeight}px`">
      <div class="block">
        <h3>组织管理</h3>
        <el-tree
          :data="treeData"
          show-checkbox
          node-key="id"
          default-expand-all
          :props="defaultProps"
        >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label }}</span>
        <span style="margin-left:10px;">
          <el-button
            type="text"
            size="mini"
            @click="() => handleOperate(data,'add',node)">
            新增
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="() => handleOperate(data, 'edit')">
            修改
          </el-button>
<!--          <el-button-->
<!--            type="text"-->
<!--            size="mini"-->
<!--            @click="() => handleDelete(data)">-->
<!--            删除-->
<!--          </el-button>-->
        </span>
      </span>
        </el-tree>
      </div>
      <div class="block">
        <h3>药监局备案
        <!--  <el-button type="text" v-if="!(systemActionPermissions.indexOf('btn_setting_dept_add') === -1)">新增</el-button> -->
        </h3>
        <el-tree
          :data="treeDataA"
          show-checkbox
          node-key="id"
          default-expand-all
          :props="defaultProps"
        >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label }}</span>
        <span style="margin-left:10px;">
          <el-button
            type="text"
            size="mini"
            @click="() => handleOperate(data,'add')">
            新增
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="() => handleOperate(data, 'edit')">
            修改
          </el-button>
<!--          <el-button-->
<!--            type="text"-->
<!--            size="mini"-->
<!--            @click="() => handleDelete(data)">-->
<!--            删除-->
<!--          </el-button>-->
        </span>
      </span>
        </el-tree>
      </div>
    </div>
    <!--新增/编辑科室-->
    <Modal
      v-model="addVisible"
      :title="title"
      :styles="{ top: '20%' }"
      :mask-closable="false"
      @on-cancel="cancel"
    >
      <Form
        ref="proTypeForm"
        :model="proTypeForm"
        :rules="rules"
        :label-width="150"
      >
        <FormItem label="部门/科室名称：" prop="name">
          <Input v-model="proTypeForm.name" placeholder="请输入部门/科室名称"/>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="cancel">取消</Button>
        <Button type="primary" :loading="loading" @click="okClick">确定</Button>
      </div>
    </Modal>
	</div>
</template>

<script>
import api from "@/api/setting/department"
import { mapState } from "vuex"
const { apiGetDeptTree, apiInsertDept, apiUpdateDept, apiDelDept } = api

export default {
	name: "Department",
	components: { },
	data() {
		return {
      loading: false,
      addVisible: false,
			searchParams: {
				// orgId: "",
				// isDel: 0,
				queryLike: ""
			},
			oldSearchParams: {
				orgId: "",
				isDel: 0,
				queryLike: ""
			},
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      treeData: [],
      treeDataA: [],
      title: "部门科室",
      operateType: "",
      proTypeForm: {
        id: "",
        pid: "",
        name: ""
      },
      oldProTypeForm: {
        id: "",
        pid: "",
        name: ""
      },
      rules: {
        name: {
          required: true,
          message: "该项不能为空"
        }
      },
      expandedKeys: [],
      expandedKeysA: []
		}
	},
	created() {
    this.initPage()
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	methods: {
    // 操作
    handleOperate(data, operate) {
      this.operateType = operate
      const { id, name } = data
      if (operate === "edit") {
        this.title = "部门/科室-编辑"
        this.proTypeForm.id = id
        this.proTypeForm.name = name
      } else {
        this.title = "部门/科室-新增"
        this.proTypeForm.pid = id
      }
      this.addVisible = true
    },
    // 删除
    handleDelete(data) {
      console.log(data)
      const { id } = data
      this.$Modal.confirm({
        title: "您确认删除这条数据吗？",
        onOk: async () => {
          const res = await apiDelDept({
            id
          })
          if (res) {
            this.$Message.success("删除成功！")
            this.searchHandle()
          }
        }
      })
    },
		initPage() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetDeptTree(this.searchParams)
				if (res) {
					this.treeData = res.data.filter(item => item.type === 0)
          this.treeDataA = res.data.filter(item => item.type === 1)
          // 展开第一个树
          // this.expandedKeys = [this.treeData[0].id]
          // this.expandedKeysA = [this.treeDataA[0].id]
				}
				this.loading = false
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				// orgId: "",
				// isDel: 0,
				queryLike: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initPage()
			})
		},
    // 树形数据
    okClick() {
      this.$refs.proTypeForm.validate((valid) => {
        if (valid) {
          this.$asyncDo(async () => {
            this.loading = true
            if (this.operateType === "add") { // 新增
              await apiInsertDept(this.proTypeForm)
            } else { // 编辑
              await apiUpdateDept(this.proTypeForm)
            }
            this.searchHandle()
            this.cancel()
            this.$Message.success("操作成功")
            this.loading = false
          })
        }
      })
    },
    cancel() {
      this.$refs.proTypeForm.resetFields();
      this.addVisible = false
    },
	}
}
</script>

<style scoped>
.custom-tree-container {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding-right: 8px;
  .block{
    width: 50%;
    flex-shrink: 1;
    h3 {
      height: 40px;
      line-height: 40px;
      font-weight: bold;
    }
  }
}
</style>
