<template>
	<Modal
		class="add-pro-modal"
		:value="visible"
		:fullscreen="fullscreen"
		:mask-closable="false"
		:styles="{ top: fullscreen ? 0 : '20px' }"
		@on-cancel="onCancel"
		width="1150"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="add-pro-modal-header">
			<h2>{{ title }}</h2>
			<a href="javascript:;" class="fullscreen" @click="handleFullScreen">
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
<!--			<Button-->
<!--				class="add-org-btn"-->
<!--				icon="md-add"-->
<!--				@click="handleAddOrg"-->
<!--				type="primary"-->
<!--				>新增申办方/SMO/CRO</Button-->
<!--			>-->
		</div>
		<div class="con" :style="`height:${conHeight}px`">
			<div>
				<designTemplate
					:componentList="componentListDetail"
					:updateId="updateId"
					:is-no-height="true"
					:is-add-org="isAddOrg"
					entry="project"
					:isBackFill="updateId ? false : true"
					:isRepeat="isRepeat"
					ref="designTemplate_add_project"
					@onItemBlur="onItemBlur"
					show-type="approval"
				></designTemplate>
				<div>
					<div v-if="isXinhua" class="genetic-wrapper">
						<h2 style="font-size: 14px; color: #515a6e">是否创建遗传办文档</h2>
						<Select v-model="isOpenHgc" style="width: 300px" clearable>
							<Option :value="1">是</Option>
							<Option :value="0">否</Option>
						</Select>
					</div>
					<div v-else class="genetic-wrapper">
						<h2>遗传办信息</h2>
						<Checkbox
							v-model="isOpenHgc"
							:disabled="!!updateId && initOpenHgc === 1"
							:true-value="1"
							:false-value="0"
						>
							是否创建遗传办文档
						</Checkbox>
					</div>
				</div>
				<!--<designTemplate v-if="isOpenHgc===1 && !isXinhua"-->
				<!--:componentList="hgcComponentListDetail"-->
				<!--:updateId="updateId"-->
				<!--:is-no-height="true"-->
				<!--entry="project"-->
				<!--ref="designTemplate_add_genetic"-->
				<!--show-type="approval"></designTemplate>-->
			</div>
		</div>

		<org-modal
			:visible="orgModalVisible"
			@closeOrgModal="closeOrgModal"
		></org-modal>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button
				type="primary"
				v-if="!(updateId && status === 1)"
				@click="onOk(0, 'save')"
				:loading="loading"
				>暂存为草稿</Button
			>
			<Button type="primary" @click="onOk(1, 'submit')" :loading="loading"
				>保存并发布</Button
			>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/project"
import designTemplate from "@/components/BusinessComponents/FormDesign/designTemplate.vue"
import OrgModal from "../Setting/Organization/indexModal.vue"
import ExtraMixin from "@/mixins/ExtraMixin"
import { mapState } from "vuex"
import moment from "moment"

const {
	apiGet,
	apiAdd,
	apiUpdate,
	updateDrafts,
	getDraftsDetail,
	addDrafts,
	apiGetRepeatPrjName
} = api

export default {
	name: "Add",
	// status 表示是 1 已发布项目 还是 0 草稿箱
	props: [
		"visible",
		"updateId",
		"status",
		"componentList",
		"projType",
		"projTypeName"
	],
	mixins: [ExtraMixin],
	components: {
		designTemplate,
		OrgModal
	},
	data() {
		return {
			loading: false,
			fullscreen: false, // 是否全屏弹出框
			orgModalVisible: false, // 是否显示申办方 SMO CRO 新增弹框
			title: "",
			orgId: this.$store.state.user.info.orgId
				? this.$store.state.user.info.orgId
				: "",
			dataGridHeight: 300,
			componentListDetail: [],
			hgcComponentListDetail: [],
			projectType: "",
			projectTypeName: "",
			currentType: "",
			currentAction: "",
			isAddOrg: false,
			isRepeat: false,
			isOpenHgc: "",
			initOpenHgc: ""
		}
	},
	computed: {
		siteCatgList() {
			return this.$store.state.enumerate.SITE_CATEGORY
		},
		isLeaderList() {
			return this.$store.state.enumerate.LEADER_STYLE
		},
		...mapState({
			contentHeight: state => state.contentHeight - 40
		}),
		conHeight() {
			return this.fullscreen
				? document.body.clientHeight - 145
				: this.contentHeight
		},
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.projectType = this.projType
				this.projectTypeName = this.projTypeName
				this.componentListDetail = this.componentList
				this.title = `新增项目——${this.projectTypeName}`
				if (this.isXinhua) {
					this.isOpenHgc = ""
				} else {
					this.isOpenHgc = 0
				}

				this.initOpenHgc = ""
				// if (this.$refs.designTemplate_add_project) {
				//   this.$refs.designTemplate_add_project.$refs.designTemplateForm.resetFields();
				// }
				if (this.$refs.designTemplate_add_genetic) {
					// const { $refs } = this.$refs.designTemplate_add_genetic;
					this.$refs.designTemplate_add_genetic.$refs.designTemplateForm.resetFields()
				}
				if (this.updateId) {
					this.title = "修改项目"
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.status === 1) {
							// 修改项目
							res = await apiGet({ id: this.updateId })
						} else {
							// 修改草稿
							res = await getDraftsDetail(this.updateId)
						}
						if (res && res.data && res.data.project) {
							if (res.data.project.jsonData) {
								const componentListDetail = JSON.parse(
									res.data.project.jsonData
								)
								this.componentListDetail = componentListDetail.map(comp => {
									let list = comp
									let json = {}
									if (typeof comp.data === "object") {
										json = comp.data
									} else {
										json = JSON.parse(comp.data)
									}
									// 修改项目的时候 主要研究者不修改 主要研究者在团队里修改
									if (
										this.status === 1 &&
										comp.alias === "trial_site_PI_name"
									) {
										json.disabled = true
									}
									// 修改项目的时候 角色下拉框不可修改
									if (this.status === 1 && json.isRequest === "2") {
										json.disabled = true
									}
									list = {
										...comp,
										data: json
									}
									return list
								})
							}
							if (
								res.data.project.isOpenHgc === 0 ||
								res.data.project.isOpenHgc === 1
							) {
								this.isOpenHgc = res.data.project.isOpenHgc
							} else {
								this.isOpenHgc = this.isXinhua ? null : 0
							}
							this.initOpenHgc = this.isOpenHgc
							this.projectType = res.data.project.projectConfigId
							this.projectTypeName = res.data.project.projectConfigName
							this.title += `——${this.projectTypeName}`
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		clickRadio(val) {},
		handleAddOrg() {
			this.orgModalVisible = true
		},
		closeOrgModal(isAddOrg) {
			this.isAddOrg = isAddOrg
			this.orgModalVisible = false
		},
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		},
		onOk(type, action) {
			if (type !== 1) {
				// 暂存时，不校验。
				this.submit(type, action)
				return
			}
			this.currentType = type
			this.currentAction = action

			const { $refs } = this.$refs.designTemplate_add_project

			$refs.designTemplateForm.validate(valid => {
				if (valid) {
					// if (this.isOpenHgc === 1 && !this.isXinhua) { // 开启遗传办
					//   this.$refs.designTemplate_add_genetic.$refs.designTemplateForm.validate((isValid) => {
					//     if (isValid) {
					//       this.submit(this.currentType, this.currentAction);
					//     } else {
					//       this.$Message.error('有必填项未填写');
					//       this.$refs.designTemplate_add_genetic.scrollToErrorFormItem();
					//     }
					//   });
					// } else {
					this.submit(this.currentType, this.currentAction)
					// }
				} else {
					this.$Message.error("有必填项未填写")
					this.$refs.designTemplate_add_project.scrollToErrorFormItem()
				}
			})
		},
		async submit(type, action) {
			this.$asyncDo(async () => {
				// let jsonDataHgc = '';
				// if (this.isXinhua) {
				//   jsonDataHgc = null;
				// } else if (this.isOpenHgc && !this.isXinhua) {
				//   jsonDataHgc = await this.getCustomFormData('designTemplate_add_genetic', this.hgcComponentListDetail);
				// } else {
				//   jsonDataHgc = null;
				// }
				this.loading = true
				let res = false
				const obj = {
					project: {
						jsonData: await this.getCustomFormData(
							"designTemplate_add_project",
							this.componentListDetail
						),
						isOpenHgc:
							this.isOpenHgc === 1 ? 1 : this.isOpenHgc === 0 ? 0 : null,
						// jsonDataHgc,
						submitStatus: type,
						projectConfigId: this.projectType
					}
				}
				let params = {}
				if (this.updateId && action === "submit") {
					if (this.status === 1) {
						// 更新已发布项目
						params = {
							jsonData: await this.getCustomFormData(
								"designTemplate_add_project",
								this.componentListDetail
							),
							isOpenHgc:
								this.isOpenHgc === 1 ? 1 : this.isOpenHgc === 0 ? 0 : null,
							// jsonDataHgc,
							id: this.updateId,
							submitStatus: type,
							projectConfigId: this.projectType
						}
						res = await apiUpdate(params)
					} else {
						// 暂存项目提交
						params = {
							project: {
								jsonData: await this.getCustomFormData(
									"designTemplate_add_project",
									this.componentListDetail
								),
								isOpenHgc:
									this.isOpenHgc === 1 ? 1 : this.isOpenHgc === 0 ? 0 : null,
								// jsonDataHgc,
								submitStatus: type,
								projectConfigId: this.projectType,
								id: this.updateId
							}
						}
						res = await apiAdd(params)
					}
				} else if (this.updateId && action === "save") {
					// 暂存后再暂存，更新草稿
					params = {
						jsonData: await this.getCustomFormData(
							"designTemplate_add_project",
							this.componentListDetail
						),
						isOpenHgc:
							this.isOpenHgc === 1 ? 1 : this.isOpenHgc === 0 ? 0 : null,
						// jsonDataHgc: this.isOpenHgc ? await this.getCustomFormData('designTemplate_add_genetic', this.hgcComponentListDetail) : null,
						id: this.updateId,
						submitStatus: type,
						projectConfigId: this.projectType
					}
					res = await updateDrafts(params)
				} else if (!this.updateId && action === "save") {
					// 暂存草稿
					params = { ...obj }
					res = await addDrafts(params)
				} else {
					// 新增项目，调用add接口
					params = { ...obj }
					res = await apiAdd(params)
				}
				if (res) {
					this.$emit("onOk", type)
					this.$Message.success("操作成功！")
				}
				this.loading = false
				this.fullscreen = false
			})
		},
		async getCustomFormData(temp, componentList) {
			const { list, formData } = this.$refs[temp]
			const newListArr = componentList.map(item => {
				const newItem = list.find(li => li.id === item.id)
				if (newItem) {
					newItem.isShow = true
					return newItem
				}
				return { ...item, isShow: false }
			})
			return newListArr.map(listItem => {
				let dataJson = {}
				if (listItem.data) {
					if (typeof listItem.data === "object") {
						dataJson = listItem.data
					} else {
						dataJson = JSON.parse(listItem.data)
					}
				}
				if (dataJson.bindType === "date") {
					if (formData[dataJson.code]) {
						dataJson.dataValue = moment(formData[dataJson.code]).format(
							"YYYY-MM-DD"
						)
					} else {
						dataJson.dataValue = ""
					}
				} else if (dataJson.bindType === "datetime") {
					if (formData[dataJson.code]) {
						dataJson.dataValue = moment(formData[dataJson.code]).format(
							"YYYY-MM-DD HH:mm:ss"
						)
					} else {
						dataJson.dataValue = ""
					}
				} else if (
					dataJson.type === "base_Select" &&
					dataJson.isRequest === "2"
				) {
					dataJson.dataValue = dataJson.userName || ""
				} else {
					dataJson.dataValue = formData[dataJson.code]
				}
				if (
					listItem.json &&
					(listItem.json.type === "base_Radio" ||
						listItem.json.type === "base_Checkbox")
				) {
					dataJson.optionList = listItem.json.optionList
				}
				//  赋值
				const obj = {}
				Object.keys(listItem).forEach(key => {
					if (key !== "json") {
						obj[key] = listItem[key]
					}
				})
				obj.data = JSON.stringify(dataJson)
				return obj
			})
		},
		handleFullScreen() {
			// 全屏查看
			this.fullscreen = !this.fullscreen
		},
		async onItemBlur(value, item) {
			// 只有环境才判断项目名称重复
			if (this.isXinhua) {
				if (item.alias === "project_name") {
					const res = await apiGetRepeatPrjName({
						projectId: this.updateId,
						projectName: value
					})
					if (res) {
						this.isRepeat = res.data
					}
				}
			}
		}
	}
}
</script>

<style lang="less">
.add-pro-modal {
	position: relative;
	.con {
		overflow: auto;
	}
	.title {
		font-size: 15px;
		font-weight: bold;
		padding: 16px 0;
		border-top: dashed 1px #515a6e;
	}
	.mrgin0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.ivu-form-item-required .ivu-form-item-label:before {
		font-size: 12px;
	}
	.add-pro-modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.add-org-btn {
			position: absolute;
			right: 100px;
			top: 11px;
			padding: 2px 15px;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
	.auto-complete-wrap {
		.ivu-select-dropdown-list {
			max-height: 200px;
		}
	}
	.genetic-wrapper {
		margin-left: 25px;
		h2 {
			font-weight: bolder;
			padding-bottom: 5px;
		}
	}
}
</style>
