<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<div v-for="(list, index) in listdata" :key="index">
			<p class="role-title">{{ list.roleName }}({{ list.userList.length }})</p>
			<div class="user-list">
				<div
					v-for="(user, idx) in list.userList"
					:key="idx"
					class="user-item item"
				>
					<img :src="defaultAvatar" alt="" @click="handleViewShow(user)" />
					<div class="user-info">
						<p :title="user.name" @click="handleViewShow(user)">
							{{ user.name }}
						</p>
						<div>
							<Button size="small" @click="handleViewShow(user)">查看</Button>
							<Poptip
								confirm
								placement="top"
								title="确定删除该成员吗？"
								:transfer="true"
								@on-ok="deleteHandle(user, list.roleId)"
								v-if="
									!(
										projectActionPermissions.indexOf('btn_project_team_add') ===
										-1
									)
								"
							>
								<Button type="error" size="small">删除</Button>
							</Poptip>
						</div>
					</div>
				</div>
				<div
					class="item add-user"
					@click="handleAddShow(list)"
					v-if="
						!(projectActionPermissions.indexOf('btn_project_team_add') === -1)
					"
				>
					<div class="add">
						<p>
							<Icon type="md-add" />
						</p>
						<p>添加成员</p>
					</div>
				</div>
			</div>
		</div>
		<Add
			:visible="addVisible"
			:role="role"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
		></Add>
		<ViewDetail
			:visible="viewVisible"
			@onCancel="viewVisible = false"
			:id="viewId"
		></ViewDetail>
	</div>
</template>

<script>
import api from "@/api/project/team"
import { mapState } from "vuex"
import Add from "./Add.vue"
import ViewDetail from "@/views/Setting/Staff/ViewDetail.vue"
import defaultAvatar from "@/assets/common/teamUser.png"

const { getTeamUsers, removeTeamUsers } = api
export default {
	name: "Team",
	data() {
		return {
			defaultAvatar,
			loading: false,
			projectId: this.$route.params.projectId,
			listdata: [],
			addVisible: false,
			role: null,
			viewVisible: false,
			viewId: ""
		}
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getTeamUsers({
					projectId: this.projectId
				})
				if (res) {
					this.listdata = res.data
				}
				this.loading = false
			})
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
			// 更新待审批流程数量(修改团队成员，角色发生改变，需要更新待办)
			this.$store.dispatch(
				"process/getProjectToDoNum",
				this.$route.params.projectId
			)
		},
		// 新增显示
		handleAddShow(obj) {
			this.role = obj
			this.addVisible = true
		},
		handleViewShow(obj) {
			this.viewVisible = true
			this.viewId = obj.id
		},
		deleteHandle(obj, roleVal) {
			this.$asyncDo(async () => {
				const res = await removeTeamUsers({
					projectId: this.projectId,
					userId: obj.id,
					roleId: roleVal
				})
				if (res) {
					this.$Message.success("删除成功")
					this.initList()
				}
			})
		},
		cancel() {}
	},
	created() {
		this.initList()
	},
	computed: {
		...mapState("permission", ["projectActionPermissions"])
	},
	components: {
		Add,
		ViewDetail
	}
}
</script>

<style scoped lang="less">
.role-title {
	font-size: 15px;
	font-weight: 800;
	line-height: 32px;
}
.user-list {
	display: table;
}
.item {
	border: solid 1px #ccc;
	text-align: center;
	display: inline-table;
	padding: 10px;
	cursor: pointer;
	border-radius: 3px;
	vertical-align: middle;
	height: 78px;
	position: relative;
	margin: 5px;
}
.item.add-user {
	width: 78px;
	padding: 0;
	.ivu-icon-md-add {
		font-size: 28px;
	}
	.add {
		width: 78px;
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}
.user-item {
	img {
		width: 50px;
	}
	.user-info {
		display: inline-table;
		vertical-align: top;
		padding-left: 10px;
		p {
			width: 100px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		& > div {
			padding-top: 8px;
			button {
				margin-left: 5px;
			}
		}
	}
}
</style>
