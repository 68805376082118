import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/auth/department"

export default {
	...commonApi(moduleName),
  apiGetDeptTree: data =>
    request('/auth/dept/tree', { // 查询获取科室列表
      method: "get",
      params: data
    }),
  apiInsertDept: data =>
    request('/auth/dept/insert', { // 新增科室列表
      method: "post",
      body: data
    }),
  apiUpdateDept: data =>
    request('/auth/dept/update', { // 修改科室列表
      method: "post",
      body: data
    }),
  apiDelDept: data =>
    request('/auth/dept', { // 删除科室列表
      method: "delete",
      params: data
    }),
}
