<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="名称">
				<Input placeholder="请输入名称" v-model="searchParams.name" />
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				v-if="
					projectActionPermissions.indexOf('btn_project_goods_drug_add') > -1
				"
				@click="() => handleDrawerShow(ROW_TYPE.medicine, 'add')"
				>新增药物</Button
			>
			<Button
				v-if="
					projectActionPermissions.indexOf('btn_project_goods_drug_add') > -1
				"
				@click="() => handleDrawerShow(ROW_TYPE.material, 'add')"
				>新增耗材</Button
			>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>

		<!--药品增删查 Add Read(View) Update-->
		<MedicineARU
			:type="medicineARUType"
			:visible="medicineVisible"
			@onCancel="medicineVisible = false"
			@onOk="() => handleDrawerOk(ROW_TYPE.medicine)"
			:sponsorList="sponsorList"
			:drugRouteList="drugRouteList"
			:drugTypeList="drugTypeList"
			:pharmacyList="pharmacyList"
			:unitList="unitList"
			:id="updateId"
		></MedicineARU>

		<!--耗材增删查 Add Read(View) Update-->
		<MaterialARU
			:type="materialARUType"
			:visible="materialVisible"
			:pharmacyList="pharmacyList"
			@onCancel="materialVisible = false"
			@onOk="() => handleDrawerOk(ROW_TYPE.material)"
			:id="materialId"
		></MaterialARU>
	</div>
</template>

<script>
import api from "@/api/project/drug"
import publicApi from "@/api/public"
import MedicineARU from "./MedicineAddOrDetail.vue"
import MaterialARU from "./MaterialAddOrDetail.vue"
import { mapState } from "vuex"

const { apiGetPage, apiGet, apiAdd, apiUpdate, apiGetPharmacy } = api
const { publicGetOrganizationList, publicGetDictionaryList } = publicApi
const { TYPE_MAP } = MedicineARU // 侧滑栏类型

export default {
	name: "index",
	components: {
		MedicineARU,
		MaterialARU
	},
	data() {
		return {
			listData: [],
			pharmacyList: [],
			columns: [
				{ title: "物品名称", key: "viewName", minWidth: 250 },
				{ title: "当前库存", key: "currAmount", minWidth: 150 },
				{ title: "总库存", key: "totalAmount", minWidth: 150 },
				{
					title: "类型",
					key: "type",
					minWidth: 100,
					render: (h, params) =>
						h("span", {}, ["药物", "耗材"][params.row.type])
				},
				{ title: "所属仓库", key: "pharmacyName", minWidth: 180 },
				{ title: "更新时间", key: "updatedTime", minWidth: 180 },
				{
					title: "操作",
					key: "action",
					width: 180,
					fixed: "right",
					renderButton: params => {
						// 需要判断类型是耗材还是药品,来决定侧滑显示哪种类型
						const rowType =
							params.row.type === 1
								? this.ROW_TYPE.material
								: this.ROW_TYPE.medicine
						const actionList = [
							{
								label: "查看",
								on: {
									click: () =>
										this.handleDrawerShow(
											rowType,
											TYPE_MAP.read.key,
											params.row.id
										)
								}
							},
							{
								label: "库存",
								on: {
									click: this.invenctoryHandle
								}
							},
							{
								label: "修改",
								on: {
									click: () =>
										this.handleDrawerShow(
											rowType,
											TYPE_MAP.update.key,
											params.row.id
										)
								}
							}
						]
						if (
							this.projectActionPermissions.indexOf(
								"btn_project_goods_drug_add"
							) === -1
						) {
							actionList[2] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			loading: false,
			medicineVisible: false,
			materialVisible: false,
			updateId: "",
			materialId: "",
			searchParams: {
				name: ""
			},
			oldSearchParams: {
				name: ""
			},
			// 分页
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			sponsorList: [],
			// 给药途径
			drugRouteList: [],
			// 临床用药分类
			drugTypeList: [],
			// 单位
			unitList: [],
			type: 1,
			medicineARUType: TYPE_MAP.add.key, // 侧滑类型，新增:add  查看:view  修改:update
			materialARUType: TYPE_MAP.add.key, // 侧滑类型，新增:add  查看:view  修改:update
			ROW_TYPE: {
				medicine: "medicine",
				material: "material"
			}
		}
	},
	created() {
		this.getPharmacyList()
		this.getBaseData()
		this.initList()
	},
	computed: {
		...mapState("project", ["infoHeight"]),
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 146 - this.infoHeight
			}
		}),
		...mapState("permission", ["projectActionPermissions"])
	},
	methods: {
		apiGet,
		apiAdd,
		apiUpdate,
		getBaseData() {
			// 获取所有类型为申办方的组织名称
			this.$asyncDo(async () => {
				const res = await publicGetOrganizationList({
					type: 101
				})
				if (res) {
					this.sponsorList = res.data
				}
			})
			// 获取数据字典--给药途径，临床用药分类
			this.$asyncDo(async () => {
				const res = await publicGetDictionaryList()
				if (res) {
					this.drugRouteList = res.data.find(item => item.id === "11").leaf
					this.drugTypeList = res.data.find(item => item.id === "2").leaf
					this.unitList = res.data.find(item => item.id === "9").leaf
				}
			})
		},
		getPharmacyList() {
			// 获取所有类型为申办方的组织名称
			this.$asyncDo(async () => {
				const res = await apiGetPharmacy({
					pageSize: 10000,
					pageNum: 1
				})
				if (res) {
					this.pharmacyList = res.data.list
				}
			})
			// 获取数据字典--给药途径，临床用药分类
			this.$asyncDo(async () => {
				const res = await publicGetDictionaryList()
				if (res) {
					this.drugRouteList = res.data.find(item => item.id === "11").leaf
					this.drugTypeList = res.data.find(item => item.id === "2").leaf
				}
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					projectId: this.$route.params.projectId,
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		invenctoryHandle({ row }) {
			if (row.type === 1) {
				// 耗材库存
				this.$router.push({
					name: "projectGoodsDrugInventoryMeterial",
					params: {
						projectId: this.$route.params.projectId
					},
					query: {
						drugId: row.id
					}
				})
			} else {
				// 药物库存
				this.$router.push({
					name: "projectGoodsDrugInventoryMedicine",
					params: {
						projectId: this.$route.params.projectId
					},
					query: {
						drugId: row.id
					}
				})
			}
		},
		// 增查改显示状态
		handleDrawerShow(componentType, openType = "", updateId = null) {
			if (!openType) {
				return
			}
			switch (componentType) {
				case this.ROW_TYPE.medicine: // 药品
					this.medicineVisible = true
					this.medicineARUType = openType
					this.updateId = updateId || ""
					break
				case this.ROW_TYPE.material: // 耗材
					this.materialVisible = true
					this.materialARUType = openType
					this.materialId = updateId || ""
					break
				default:
					break
			}
		},
		// 增查改退出状态
		handleDrawerOk(componentType) {
			switch (componentType) {
				case this.ROW_TYPE.medicine: // 药品
					this.medicineVisible = false
					break
				case this.ROW_TYPE.material: // 耗材
					this.materialVisible = false
					break
				default:
					break
			}
			this.$nextTick(() => {
				this.initList()
			})
		},

		// 重置
		reset() {
			this.searchParams = {
				name: ""
			}
			this.page.pageSize = 10
			this.searchHandle()
		},
		// 搜索
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>
