<template>
	<!--研究成果 填写-->
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>

		<Tabs v-model="tabName">
			<TabPane
				:label="item.templateName"
				:name="item.templateId.toString()"
				v-for="item in tabList"
				:key="item.templateId"
			>
				<div :style="`height:${dataGridHeight}px`" style="overflow: auto">
					<designTemplate
						:is-no-height="true"
						:updateId="projectId"
						:componentList="item.formJson"
						:ref="`designTemplate_${item.templateId}`"
						:showType="isEdit ? 'approval' : 'history'"
					>
					</designTemplate>
				</div>
				<div
					v-if="isEdit && item.formJson && item.formJson.length"
					style="text-align: right; padding-right: 30px"
				>
					<Button
						style="margin-right: 18px"
						@click="onCancel"
						:loading="loading"
						>取消</Button
					>
					<Button type="primary" @click="onOk(item)" :loading="loading"
						>提交</Button
					>
				</div>
			</TabPane>
		</Tabs>
	</div>
</template>

<script>
import api from "@/api/achievement/achievement"
import designTemplate from "@/components/BusinessComponents/FormDesign/designTemplate.vue"
import { mapState } from "vuex"
import moment from "moment"

const { getProjectAchieveForm, apiSaveProjectAchieveForm } = api

export default {
	name: "fillform",
	components: {
		designTemplate
	},
	props: ["isDisabled", "updateId"],
	data() {
		return {
			loading: false,
			tabList: [],
			tabName: ""
		}
	},
	computed: {
		projectId() {
			return this.$route.params.projectId || this.updateId
		},
		...mapState({
			dataGridHeight: state => state.contentHeight - 185
		}),
		...mapState("permission", ["projectActionPermissions"]),
		isEdit() {
			return !this.isDisabled
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getProjectAchieveForm({ projectId: this.projectId })
				if (res && res.data) {
					this.tabList = res.data.map(item => {
						let json = []
						if (item.formJson) {
							if (typeof item.formJson === "object") {
								json = item.formJson
							} else {
								json = JSON.parse(item.formJson)
							}
						}
						return {
							...item,
							formJson: item.formJson ? json : []
						}
					})
				}
				this.loading = false
			})
		},
		// 取消
		onCancel() {
			this.init()
		},
		// 提交
		onOk(item) {
			const { $refs } = this.$refs[`designTemplate_${item.templateId}`][0]

			$refs.designTemplateForm.validate(isValid => {
				if (isValid) {
					this.submit(`designTemplate_${item.templateId}`, item)
				}
			})
		},
		submit(temp, item) {
			this.$asyncDo(async () => {
				this.loading = true
				let json = []
				if (item.formJson && item.formJson.length) {
					json = await this.getCustomFormData(temp, item.formJson)
				}
				const params = {
					projectId: this.projectId,
					templateId: item.templateId,
					formJson: item.formJson ? JSON.stringify(json) : null
				}
				const res = await apiSaveProjectAchieveForm(params)
				if (res) {
					this.$Message.success("操作成功！")
					this.init()
				}
				this.loading = false
			})
		},
		async getCustomFormData(temp, componentList) {
			const { list, formData } = this.$refs[temp][0]
			const newListArr = componentList.map(item => {
				const newItem = list.find(li => li.id === item.id)
				if (newItem) {
					newItem.isShow = true
					return newItem
				}
				return { ...item, isShow: false }
			})
			return newListArr.map(listItem => {
				let dataJson = {}
				if (listItem.data) {
					if (typeof listItem.data === "object") {
						dataJson = listItem.data
					} else {
						dataJson = JSON.parse(listItem.data)
					}
				}
				if (dataJson.bindType === "date") {
					if (formData[dataJson.code]) {
						dataJson.dataValue = moment(formData[dataJson.code]).format(
							"YYYY-MM-DD"
						)
					} else {
						dataJson.dataValue = ""
					}
				} else if (dataJson.bindType === "datetime") {
					if (formData[dataJson.code]) {
						dataJson.dataValue = moment(formData[dataJson.code]).format(
							"YYYY-MM-DD HH:mm:ss"
						)
					} else {
						dataJson.dataValue = ""
					}
				} else if (
					dataJson.type === "base_Select" &&
					dataJson.isRequest === "2"
				) {
					dataJson.dataValue = dataJson.userName || ""
				} else {
					dataJson.dataValue = formData[dataJson.code]
				}
				if (
					listItem.json &&
					(listItem.json.type === "base_Radio" ||
						listItem.json.type === "base_Checkbox")
				) {
					dataJson.optionList = listItem.json.optionList
				}
				//  赋值
				const obj = {}
				Object.keys(listItem).forEach(key => {
					if (key !== "json") {
						obj[key] = listItem[key]
					}
				})
				obj.data = JSON.stringify(dataJson)
				return obj
			})
		}
	}
}
</script>

<style lang="less">
.follow-check-modal {
	.follow-check-form {
		padding: 10px 20px 0;
	}
	.reason-input {
		width: 500px;
		textarea.ivu-input {
			font-size: 14px;
		}
	}
}
</style>
