<template>
	<div class="content-wrap humiture">
		<Spin fix v-if="loading"></Spin>

		<!--搜索框-->
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="2" label="开始结束时间" placement="top">
				<DatePicker
					type="datetimerange"
					placeholder="请选择开始结束时间"
					:transfer="true"
					style="width: 100%"
					v-model="times"
				></DatePicker>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>

		<!--操作按钮-->
		<CtmsAction>
			<Button @click="toBack">返回</Button>
			<Button
				@click="handleAddShow"
				v-if="systemActionPermissions.indexOf('btn_pharmacy_add') > -1"
				>新增记录</Button
			>
		</CtmsAction>
		<CtmsAction>
			<Row>
				<Col span="6">仓库编号：{{ pharmacyInfo.pharmacyNo }}</Col>
				<Col span="6">仓库名称：{{ pharmacyInfo.pharmacyName }}</Col>
				<Col span="6"
					>温度警戒区间：{{ pharmacyInfo.tempMin }}℃~{{
						pharmacyInfo.tempMax
					}}℃</Col
				>
				<Col span="6"
					>湿度警戒区间：{{ pharmacyInfo.humMin }}%~{{
						pharmacyInfo.humMax
					}}%</Col
				>
			</Row>
		</CtmsAction>

		<!--主内容-->
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>

		<!--新增记录侧边栏-->
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:pharmacyId="pharmacyId"
			:pharmacyNo="pharmacyInfo.pharmacyNo"
			:pharmacyName="pharmacyInfo.pharmacyName"
		></Add>
	</div>
</template>

<script>
import api from "@/api/pharmacy/humiture/index"
import Moment from "moment"
import Add from "./Add.vue"
import { mapState } from "vuex"

const { apiGetPage, apiDelete, apiGetPharmacyDetail } = api

export default {
	name: "pharmacyAdd",
	components: {
		Add
	},
	watch: {
		$route() {
			if (this.$route.query.pharmacyId) {
				this.getPharmacyDetail()
				this.initList()
			}
		}
	},
	data() {
		return {
			listData: [],
			columns: [
				{
					title: "温度",
					key: "temperature",
					minWidth: 100,
					render: (h, params) =>
						h(
							"span",
							params.row.temperature !== null
								? `${params.row.temperature}℃`
								: ""
						)
				},
				{
					title: "湿度",
					key: "humidity",
					minWidth: 150,
					render: (h, params) =>
						h(
							"span",
							params.row.humidity !== null ? `${params.row.humidity}%` : ""
						)
				},
				{
					title: "记录时间",
					key: "recordDate",
					minWidth: 120,
					render: (h, params) =>
						h(
							"span",
							Moment(params.row.recordDate).format("YYYY-MM-DD HH:mm:ss")
						)
				},
				{ title: "创建时间", key: "createdTime", minWidth: 120 },
				{
					title: "操作",
					key: "action",
					width: 100,
					renderButton: () => {
						const actionList = [
							{
								label: "删除",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf("btn_pharmacy_add") === -1
						) {
							actionList[0] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			pharmacyInfo: {},
			loading: false,
			addVisible: false,
			pharmacyId: "",
			times: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				startDate: this.times ? this.times[0] : "",
				endDate: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
				startDate: this.times ? this.times[0] : "",
				endDate: this.times ? this.times[1] : ""
			}
		}
	},
	created() {
		this.getPharmacyDetail()
		this.initList()
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 180
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	methods: {
		getPharmacyDetail() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPharmacyDetail({
					pharmacyId: this.$route.query.pharmacyId
				})
				if (res) {
					this.pharmacyInfo = res.data
				}
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					pharmacyId: this.$route.query.pharmacyId
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 返回
		toBack() {
			this.$router.push({ name: "pharmacyList" })
		},
		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.pharmacyId = this.$route.query.pharmacyId
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},

		// 修改显示
		handleUpdateShow({ row }) {
			this.addVisible = true
			this.updateId = row.id
		},

		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.times = []
			this.searchParams = {}
			this.searchHandle()
		},
		searchHandle() {
			if (this.times.length) {
				Object.assign(this.searchParams, {
					startDate: Moment(this.times[0]).format("YYYY-MM-DD HH:mm:ss"),
					endDate: Moment(this.times[1]).format("YYYY-MM-DD HH:mm:ss")
				})
			} else {
				Object.assign(this.searchParams, {
					startDate: "",
					endDate: ""
				})
			}
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 禁用
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await apiDelete({
					tempId: row.id,
					pharmacyId: this.$route.query.pharmacyId
				})
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		}
	}
}
</script>
<style lang="less" scoped>
.humiture {
	&-operation {
		display: flex;
		justify-content: space-between;
	}
}
</style>
