<template>
	<div class="content-wrap">
		<Spin fix v-if="progressListLoading"></Spin>
		<Spin fix v-if="loading"></Spin>
		<div class="project-process-title">
			<h2>流程进度</h2>
			<div class="information">
				项目进度颜色说明：
				<span class="label">已完成</span>
				<img
					src="../../../assets/images/project/flagOrange.png"
					width="35"
					height="35"
				/>
				<span class="label">进行中</span>
				<img
					src="../../../assets/images/project/flagBlue.png"
					width="35"
					height="35"
				/>
			</div>
		</div>
		<div class="project-process-body" style="height: calc(100% - 35px)">
			<ul class="__content">
				<li
					v-for="(item, index) in progressListData"
					:class="getBtnClassByProjectStatus(index)"
					:key="index"
				>
					<h2 class="__header">{{ item.label }}</h2>
					<div class="__body" style="height: calc(100% - 55px)">
						<Button
							type="primary"
							size="large"
							:key="i"
							:class="getColorClass(process.color)"
							long
							:title="`已启动流程：${process.processes.length}`"
							@click="handleProcessListShow(process)"
							v-for="(process, i) in item.list"
						>
							<span>{{ process.name }}</span>
							<div class="flag">
								<div class="processing-num" v-if="process.processingNum">
									<span>{{ process.processingNum }}</span>
									<img
										src="../../../assets/images/project/flagBlue.png"
										width="35"
										height="35"
									/>
								</div>
								<div class="complete-num" v-if="process.completeNum">
									<span>{{ process.completeNum }}</span>
									<img
										src="../../../assets/images/project/flagOrange.png"
										width="35"
										height="35"
									/>
								</div>
							</div>
						</Button>
					</div>
				</li>
			</ul>
		</div>
		<List
			:visible="listVisible"
			:category="category"
			@startOk="handleOk"
			@onCancel="listVisible = false"
			:modelId="modelId"
		></List>
	</div>
</template>

<script>
import { forEach } from "lodash"
import { mapState } from "vuex"
import List from "./List.vue"

export default {
	name: "index",
	components: {
		List
	},
	data() {
		return {
			modelId: "",
			category: "",
			listVisible: false,
			loading: false
		}
	},
	created() {
		this.initList()
	},
	computed: {
		...mapState("project", ["projectInfo"]),
		...mapState("user", ["processList"]),
		...mapState("process", ["progressListData", "progressListLoading"]),
		isXinhua() {
			// 环境
			return this.$store.state.environment === "xinhua"
		}
	},
	methods: {
		// 更新流程列表
		initList() {
			this.loading = true
			this.$store.dispatch("user/getProcessList").then(() => {
				this.$store
					.dispatch("process/getProgressListData", {
						projectId: this.$route.params.projectId,
						processList: this.processList
					})
					.then(() => {
						this.loading = false
					})
			})
		},
		// 查看已启动列表
		handleProcessListShow(row) {
			this.listVisible = true
			this.modelId = row.sysModelId
			this.category = row.category
		},
		// 启动成功
		handleOk() {
			this.listVisible = false
			this.initList() // 更新流程列表
			this.loading = true
			// 更新待审批流程数量
			this.$store.dispatch(
				"process/getProjectToDoNum",
				this.$route.params.projectId
			)
			// 获取进行中流程的数据（判断各类流程的状态）
			this.$store.dispatch(
				"project/getProjectProcessNum",
				this.$route.params.projectId
			)
			// 更新项目信息（提交流程，项目状态改变）
			this.$store.dispatch(
				"project/getProjectInfo",
				this.$route.params.projectId
			)
			// 更新项目列表
			this.$store.dispatch("project/getProjectList")
			this.loading = false
		},
		// 根据项目状态确定流程按钮的颜色
		getBtnClassByProjectStatus(index) {
			if (this.projectInfo) {
				if (this.isXinhua) {
					switch (this.projectInfo.status) {
						case 0:
						case 1:
							return index === 0 ? "btn-color" : "btn-disabled"
						case 2:
							return index === 1 ? "btn-color" : "btn-disabled"
						case 3:
							return index === 3 ? "btn-color" : "btn-disabled"
						case 4:
							return index === 2 ? "btn-color" : "btn-disabled"
						case 5:
							return "btn-disabled"
						default:
							return "btn-color"
					}
				} else {
					switch (this.projectInfo.status) {
						case 0:
						case 1:
							return index === 0 ? "btn-color" : "btn-disabled"
						case 2:
							return index > 0 && index < 3 ? "btn-color" : "btn-disabled"
						case 3:
							return index === 3 ? "btn-color" : "btn-disabled"
						default:
							return "btn-color"
					}
				}
			}
			return ""
		},
		//  根据流程颜色显示
		getColorClass(type) {
			let color = "pink"
			switch (type) {
				case 0:
					color = "pink"
					break
				case 1:
					color = "blue"
					break
				case 2:
					color = "green"
					break
				default:
					color = "pink"
					break
			}
			return color
		}
	}
}
</script>

<style lang="less" scoped>
.project-process-title {
	height: 35px;
	position: relative;
	h2 {
		font-size: 18px;
		color: #202730;
		line-height: 35px;
	}
	.information {
		position: absolute;
		right: 0;
		top: 0;
		.label {
			margin-right: 10px;
		}
		img {
			margin-right: 10px;
			height: 35px;
		}
	}
}
.project-process-body {
	height: 100%;
	.__title {
		height: 50px;
		line-height: 50px;
		font-size: 20px;
		padding-left: 16px;
	}
	.__content {
		display: flex;
		list-style: none;
		height: 100%;
		li {
			height: 100%;
			flex: 1;
			.__header {
				&:after {
					background-image: url("../../../assets/images/project/progress1.png");
				}
			}
			/*&:nth-child(1){*/
			/*.__header{*/
			/*&:after{*/
			/*background-image: url("../../../assets/images/project/progress1.png");*/
			/*}*/
			/*}*/
			/*}*/
			/*&:nth-child(2){*/
			/*.__header{*/
			/*&:after{*/
			/*background-image: url("../../../assets/images/project/progress2.png");*/
			/*}*/
			/*}*/
			/*}*/
			/*&:nth-child(3){*/
			/*.__header{*/
			/*&:after{*/
			/*background-image: url("../../../assets/images/project/progress3.png");*/
			/*}*/
			/*}*/
			/*}*/
			/*&:nth-child(4){*/
			/*.__header{*/
			/*&:after{*/
			/*background-image: url("../../../assets/images/project/progress4.png");*/
			/*}*/
			/*}*/
			/*}*/
			&.btn-disabled {
				.__header {
					&:after {
						background-image: url("../../../assets/images/project/progress4.png");
					}
				}
				button {
					color: #fff;
					background: #bababa;
					border-color: #bababa;
				}
			}
			&.btn-color {
				.pink {
					color: #ff0033;
					background: #ffeef2;
					border-color: #ffeef2;
				}
				.blue {
					color: #144cff;
					background: #ebf0fe;
					border-color: #ebf0fe;
				}
				.green {
					color: #00a321;
					background: #e9f7e3;
					border-color: #ebf0fe;
				}
			}
			.__header {
				text-align: center;
				font-size: 16px;
				border-bottom: 10px solid #eee;
				position: relative;
				padding-bottom: 21px;
				&:after {
					content: "";
					display: block;
					width: 40px;
					height: 40px;
					left: 50%;
					bottom: -25px;
					position: absolute;
					margin-left: -20px;
				}
			}
			.__body {
				padding: 24px;
				overflow: auto;
				button {
					margin-bottom: 32px;
					position: relative;
					.flag {
						position: absolute;
						top: -18px;
						right: -18px;
						> div {
							display: inline-block;
							position: relative;
							span {
								display: block;
								position: absolute;
								right: -5px;
								top: 0px;
								width: 52px;
								height: 45px;
								text-align: center;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
}
</style>
