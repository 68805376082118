<template>
	<div class="content-wrap">
		<!--IitReport报表-->
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
      <CtmsSearchBoxItem :span="1" label="项目名称">
        <Input
          v-model="searchParams.projectName"
          placeholder="项目名称"
        ></Input>
      </CtmsSearchBoxItem>
      <CtmsSearchBoxItem :span="1" label="创建日期">
        <DatePicker
          type="daterange"
          placeholder="请选择"
          :transfer="true"
          style="width: 100%"
          format="yyyy-MM-dd"
          :value="times"
          @on-change="v => (times = v)"
        ></DatePicker>
      </CtmsSearchBoxItem>
      <CtmsSearchBoxItem :span="1" label="项目阶段">
        <Select
          v-model="searchParams.type"
          :transfer="true"
          placeholder="请选择类型"
        >
          <Option :value="1">入账</Option>
          <Option :value="2">出账</Option>
        </Select>
      </CtmsSearchBoxItem>
      <CtmsSearchBoxItem :span="1" label="研究型病房项目">
        <Select
          v-model="searchParams.type"
          :transfer="true"
          placeholder="请选择类型"
        >
          <Option :value="1">入账</Option>
          <Option :value="2">出账</Option>
        </Select>
      </CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button @click="exportData">导出IIT报表</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="data"
      :page="page"
			ref="table"
		>
			<template slot-scope="{ row }" slot="information">
				<span>
					{{
						row.information
							? inAccountInfo.find(item => item.value === row.information).label
							: ""
					}}
				</span>
			</template>
		</CtmsDataGrid>
	</div>
</template>

<script>
import fundsApi from "@/api/project/funds"
import { mapState } from "vuex"

const { apiGetDeparts, apiGetReportFundsListPage, apiGetReportFundsDetail } =
	fundsApi

export default {
	name: "IitReport",
	data() {
		return {
			loading: false,
			entry: "",
			updateId: "",
			fundsType: "", // 1 入账 / 2 出账
			// 科室
			departList: [],
			// 项目基本信息
			projectInfo: {},
			times: [],
			searchParams: {
        projectName: "",
				approvedStatus: "",
				type: "",
				arrivalTimeStart: this.times ? this.times[0] : "",
				arrivalTimeEnd: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
        projectName: "",
				approvedStatus: "",
				type: "",
				arrivalTimeStart: this.times ? this.times[0] : "",
				arrivalTimeEnd: this.times ? this.times[1] : ""
			},
			data: [
        {"a":"质谱多组学检测及指纹图谱分析在卵巢癌诊治中的应用实践","b":"在研阶段","c":"进行中","d":"KS23167","e":"2023.4.3","f":"KS23167","g":"2023.4.3","h":"否","i":"","j":"","k":"","l":"","m":"","n":"","o":"","p":"","q":"2022.10.20","r":50,"s":"2023.4.4","t":"2023.4.4","u":"2023.4.4","v":"NA","w":4,"x":1168,"y":1168},
      ],
			columns: [
				{
					title: "项目名称",
					key: "a",
					minWidth: 250
				},
				{
					title: "项目所处大阶段",
					key: "b",
					minWidth: 140,
				},
				{
					title: "项目具体阶段",
					key: "c",
					minWidth: 120
				},
				{
					title: "伦理受理号",
					key: "d",
					minWidth: 130
				},
				{
					title: "伦理受理日期",
					key: "e",
					minWidth: 120
				},
				{
					title: "首次伦理批件号",
					key: "f",
					minWidth: 150
				},
				{
					title: "首次伦理批件日期",
					key: "g",
					minWidth: 150
				},
				{
					title: "是否办理人类遗传办批件",
					key: "h",
					minWidth: 130
				},
				{
					title: "人类遗传办批件申请事项",
					key: "i",
					minWidth: 120
				},
				{
					title: "是否办理人类遗传办批件申请类别",
					key: "j",
					minWidth: 150
				},
				{
					title: "是否办理人类遗传办批件-通知时间",
					key: "k",
					minWidth: 150
				},
				{
					title: "人遗审批/备案日期",
					key: "l",
					minWidth: 120
				},
				{
					title: "人遗批件号",
					key: "m",
					minWidth: 120
				},
				{
					title: "合同签署日期",
					key: "n",
					minWidth: 120
				},
				{
					title: "合同金额（万元）",
					key: "o",
					minWidth: 150
				},
				{
					title: "首笔款到账金额",
					key: "p",
					minWidth: 140,
					slot: "information"
				},
				{
					title: "首笔款到账日期",
					key: "q",
					minWidth: 140
				},
				{
					title: "累积已到账金额",
					key: "r",
					minWidth: 140
				},
				{
					title: "项目启动日期",
					key: "s",
					minWidth: 120
				},
				{
					title: "本机构第一例受试者签署知情同意书的日期",
					key: "t",
					minWidth: 180
				},
				{
					title: "本机构第一例受试者入组的日期",
					key: "u",
					minWidth: 140
				},
				{
					title: "试验终止日期",
					key: "v",
					minWidth: 120
				},
				{
					title: "招募状态",
					key: "w",
					minWidth: 140
				},
				{
					title: "已筛选例数",
					key: "x",
					minWidth: 120
				},
				{
					title: "已入组",
					key: "y",
					minWidth: 120
				}
			],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 140
		})
	},
	mounted() {
		this.getPublicData()
		// this.initData()
	},
	methods: {
		getPublicData() {
			// 获取科室
			this.$asyncDo(async () => {
				const res = await apiGetDeparts()
				if (res) {
					this.departList = res.data || []
				}
			})
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetReportFundsListPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					classification: 1
				})
				if (res) {
					this.data = res.data.list || []
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		exportData() {
			/*
			 * classification 费用分类：1、机构 2、伦理
			 * type 费用类型：1、入账 2、出账
			 * arrivalTimeStart 入/出账时间-开始
			 * arrivalTimeEnd 入/出账时间-结束
			 * */
			const type = this.searchParams.type || ""
			const start = this.searchParams.arrivalTimeStart || ""
			const end = this.searchParams.arrivalTimeEnd || ""
			const approvedStatus = this.searchParams.approvedStatus === undefined
					? ""
					: this.searchParams.approvedStatus
			const url = `${this.$baseUrl}/report/outlay/export?classification=1&type=${type}&arrivalTimeStart=${start}&arrivalTimeEnd=${end}&approvedStatus=${approvedStatus}&token=${this.$store.state.user.token}`
			window.open(url)
		},
		// 重置
		reset() {
			this.times = []
			this.searchParams.type = ""
			this.searchParams.approvedStatus = ""
			this.searchHandle()
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		},
		searchHandle() {
			this.searchParams.arrivalTimeStart = this.times.length
				? this.times[0]
				: ""
			this.searchParams.arrivalTimeEnd = this.times.length ? this.times[1] : ""
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initData()
			})
		}
	}
}
</script>
