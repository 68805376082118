import request from "@/utils/request"
const moduleName = "Ctms"
export default {
  // 获取项目列表
  apiGetProjectInfoList: data =>
  request(`${moduleName}/vProject/getProjectInfoList`, {
    method: "get",
    params: data
  }),
  // 获取受试者信息
  apiGetProjectObjectInfo: data =>
  request(`${moduleName}/krProjectObject/getProjectObjectInfo`, {
    method: "post",
    body: data
  }),
  // 绑定受试者和项目信息
  apiSaveProjectObjectInfo: data =>
  request(`${moduleName}/krProjectObject/saveProjectObjectInfo`, {
    method: "post",
    body: data
  }),
}
