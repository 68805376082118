<template>
	<!--复用已有文件-->
	<Modal
    class="config-form-list-modal"
		:value="visible"
		width="500"
		:fullscreen="fullscreen"
		:scrollable="true"
		:mask-closable="false"
    @on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="modal-header">
			<h2>复用已有文件</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
    <el-tree
      :data="treeData"
      show-checkbox
      default-expand-all
      node-key="id"
      ref="tree"
      highlight-current
      :props="defaultProps"
      style="height: 280px;"
    >
    </el-tree>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="onCancel">取消</Button>
      <Button type="primary" :loading="loading" @click="onOk">提交</Button>
    </div>
	</Modal>
</template>

<script>
import api from "@/api/workbench/workbench"
import { mapState } from "vuex"

const { getDocfiles, submitReuse } = api

export default {
	name: "copyWord",
	props: ["visible", "toDoId"],
	data() {
		return {
			loading: false,
			fullscreen: false,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    };
	},
	watch: {
		visible() {
			if (this.visible) {
				this.initTree()
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 200
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 100
				: this.dataGridHeight
		}
	},
	methods: {
		// 初始化列表
		initTree() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getDocfiles(this.toDoId)
				if (res) {
					this.treeData = res.data || []
				}
				this.loading = false
			})
		},
		// 关闭
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		},
    // 将目标文件加入复用
    onOk() {
      const data = this.$refs.tree.getCheckedNodes();
      const dataArr = data.map(item => item.id)
      if (!dataArr.length) {
        this.$Message.warning('暂无选中复用文件');
        return
      }
      this.$asyncDo(async () => {
        this.loading = true
        const res = await submitReuse(this.toDoId, {ids: dataArr})
        if (res) {
          this.$Message.success('操作成功！');
        }
        this.loading = false
        this.$emit("onOk")
        this.fullscreen = false
      })
    }
	}
}
</script>

<style lang="less">
.config-form-list-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
</style>
