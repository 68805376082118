import fetch from "isomorphic-fetch"
import { Message, Modal } from "view-design"
import store from "../store/index"
import { addLog, paramsStringify } from "./util"
import router from "@/router"

const REQUEST_TIMEOUT = 60 * 60 // 请求超时时间：秒

// 防止接口多次提示
let isOut = false

// 检测response数据，返回data
export const httpLoad = res => {
	if (res) {
		switch (window.parseInt(res.code)) {
			// 请求成功
			case 0:
				isOut = false
				return res
			// 验证不通过，重新登录
			case 10001:
				if (!isOut) {
					isOut = true
					Message.error("登录过期!")
					Modal.remove() // 全局关闭对话框
					store.commit("user/clearSession")
				}
				return false
			// 单点登录
			case 10004:
				if (!isOut) {
					isOut = true
					Message.error(
						"当前账号已在其他地方登陆，您已被迫下线，请注意账号安全!"
					)
					Modal.remove() // 全局关闭对话框
					store.commit("user/clearSession")
				}
				return false
			// 修改权限
			case 10005:
				if (!isOut) {
					isOut = true
					Message.error("当前账号权限发生变更，请重新登录!")
					Modal.remove() // 全局关闭对话框
					store.commit("user/clearSession")
					store.commit("permission/clearMenuPermissions", {}, { root: true })
				}
				return false
			case 10006:
				if (!isOut) {
					isOut = true
					Message.error("无此项目权限!")
					Modal.remove() // 全局关闭对话框
					router.push("/403")
				}
				return false
			default:
				Message.error(res.message)
				return false
		}
	}
	Message.error("网络错误，请刷新重试！")
	return false
}

function parseJSON(response) {
	return response.json()
}

function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response
	}
	if (response.status === 401 || response.status === 403) {
		// 验证不通过，重新登录
		store.dispatch("user/logout")
		return false
	}
	addLog(response.url, {
		status: response.status,
		statusText: response.statusText
	})

	// 请求错误
	const error = new Error(response.statusText)
	error.response = response
	throw error
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options = {}) {
	const {
		method = "get",
		headers = {},
		params = {},
		body = {},
		signal
	} = options
	if (body) {
		Object.keys(body).forEach(key => {
			if (body[key] && typeof body[key] === "string") {
				body[key] = body[key].trim()
			}
		})
	}
	if (params) {
		Object.keys(params).forEach(key => {
			if (params[key] && typeof params[key] === "string") {
				params[key] = params[key].trim()
			}
		})
	}
	const requestUrl = Object.keys(params).length
		? `${process.env.VUE_APP_REQUEST_BASE_URL + url}?${paramsStringify(params)}`
		: process.env.VUE_APP_REQUEST_BASE_URL + url
	let requestOptions = {}
	const contentType = "application/json"
	if (method.toUpperCase() === "GET" || method.toUpperCase() === "HEAD") {
		requestOptions = {
			method,
			headers: new Headers({
				token: store.state.user.token,
				"Content-Type": contentType,
				...headers
			})
		}
	} else {
		let requestBody = ""

		// 判断传入的参数是否是formData
		if (Object.prototype.toString.call(body) === "[object FormData]") {
			requestBody = body
			requestOptions = {
				method,
				headers: new Headers({
					token: store.state.user.token,
					...headers
				}),
				body: requestBody
			}
		} else {
			requestBody = JSON.stringify(body)
			requestOptions = {
				method,
				headers: new Headers({
					token: store.state.user.token,
					"Content-Type": contentType,
					...headers
				}),
				body: requestBody
			}
		}
	}
	const promiseRequest = Promise.race([
		fetch(requestUrl, {
			...requestOptions,
			credentials: "include",
			signal
		}),
		new Promise((resolve, reject) => {
			setTimeout(() => {
				reject(new Error("request timeout"))
			}, REQUEST_TIMEOUT * 1000)
		})
	])
		.then(checkStatus)
		.then(parseJSON)
		.then(data => httpLoad(data))
		.catch(err => {
			console.error(err)
			if (err && err.message === "request timeout") {
				addLog(url, {
					status: "time out",
					statusText: "time out",
					requestTime: `${REQUEST_TIMEOUT}s`
				})
			}
			if (err && err.name === "AbortError") {
				// alert('请求被中断');
			} else {
				Message.error({
					content: "网络错误，请刷新重试！",
					duration: 5
				})
			}
		})
	return promiseRequest
}
