<template>
	<!--研究型病房入住信息补充-->
	<Modal
		class="supplementary-info-modal"
		:value="visible"
    width="1200"
		title="研究型病房入住信息补充"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			v-if="visible"
			:model="formData"
			ref="supplementaryInfoForm"
			:rules="rules"
			label-position="top"
			class="supplementary-info-form"
		>
      <Row :gutter="32">
        <Col span="12">
          <FormItem label="项目名称" prop="patientName">
            <Input v-model="formData.patientName" placeholder="请输入项目名称" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="项目等级" prop="sourceType">
            <Select
              v-model="formData.sourceType"
              placeholder="请选择项目等级"
            >
              <Option :value="1">门诊</Option>
              <Option :value="2">住院</Option>
            </Select>
          </FormItem>
          <p style="color:red; margin-top: -15px;">IST项目:项目级别请选择高水平GCP项目的具体类别-牵头国际多中心项目，牵头国内多中心项目、参与国际多中心项目、|类新药|期、需要审批的Ⅲ类器械、其他IST项目</p>
          <p style="color:red;">IIT项目:若为纵向课题，请选择纵向课题的级别-国家级、省部级、市局级、校级及学会级、院级;若为与企业合作，请选择医企协同横向项目;其他ITT项目</p>
        </Col>
      </Row>
      <Row :gutter="32">
        <Col span="12">
          <FormItem label="访视周期" prop="patientName">
            <Input v-model="formData.patientName" placeholder="请输入访视周期" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="项目进度" prop="sourceType">
            <Select
              v-model="formData.sourceType"
              placeholder="请选择项目进度"
            >
              <Option :value="1">门诊</Option>
              <Option :value="2">住院</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="32">
        <Col span="12">
          <FormItem label="责任医生" prop="sourceType">
            <Select
              v-model="formData.sourceType"
              placeholder="请选择责任医生"
            >
              <Option :value="1">门诊</Option>
              <Option :value="2">住院</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="责任护士" prop="sourceType">
            <Select
              v-model="formData.sourceType"
              placeholder="请选择责任护士"
            >
              <Option :value="1">门诊</Option>
              <Option :value="2">住院</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="32">
        <Col span="12">
          <FormItem label="CRU对接人" prop="sourceType">
            <Select
              v-model="formData.sourceType"
              placeholder="IIT项目填写"
            >
              <Option :value="1">门诊</Option>
              <Option :value="2">住院</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="质控对接人" prop="sourceType">
            <Select
              v-model="formData.sourceType"
              placeholder="IST项目填写"
            >
              <Option :value="1">门诊</Option>
              <Option :value="2">住院</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="32">
        <Col span="12">
          <FormItem label="生物样本累计采集例数" prop="patientName">
            <Input v-model="formData.patientName" placeholder="请输入生物样本累计采集例数" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="本院SAE例次" prop="patientName">
            <Input v-model="formData.patientName" placeholder="请输入本院SAE例次" />
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="32">
        <Col span="12">
          <FormItem label="已质控次数" prop="patientName">
            <Input v-model="formData.patientName" placeholder="请输入已质控次数" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="上次质控时间" prop="sourceType">
            <DatePicker
              type="date"
              style="width: 100%"
              v-model="formData.dischargeTime"
              format="yyyy-MM-dd"
              placeholder="上次质控时间"
            >
            </DatePicker>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="32">
        <Col span="12">
          <FormItem label="上报方案违背/偏离例次" prop="patientName">
            <Input v-model="formData.patientName" placeholder="请输入上报方案违背/偏离例次" />
          </FormItem>
        </Col>
      </Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">提交</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/project"
import { mapState } from "vuex"
import moment from "moment"

const { apiSetFollowCheck } = api

export default {
	name: "followCheck",
	props: ["visible"],
	data() {
		return {
			loading: false,
			tip: "提醒日早10:00会以短信形式通知项目内申办方角色",
			color: "#666",
			formData: {
				componentValue: "",
				notifyType: null,
				messageDate: "",
				notifyFrequency: 2
			},
			rules: {
				componentValue: {
					required: true,
					message: "伦理批件签发日期不能为空",
					trigger: "blur"
				},
				// messageDate: {
				//   required: true, message: '短信提醒日期不能为空', trigger: 'blur',
				// },
				notifyType: {
					required: true,
					type: "number",
					message: "伦理批件有效期不能为空",
					trigger: "change"
				}
			},
			nowDate: ""
		}
	},
	computed: {
		...mapState("project", ["followCheckData"])
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$store
					.dispatch("project/getFollowCheck", this.$route.params.projectId)
					.then(() => {
						this.$nextTick(() => {
							this.formData = {
								...this.followCheckData,
								notifyFrequency:
									this.followCheckData.notifyType === 100 &&
									!this.followCheckData.notifyFrequency
										? 2
										: this.followCheckData.notifyFrequency
							}
							this.$refs.supplementaryInfoForm &&
								this.$refs.supplementaryInfoForm.resetFields()
							if (this.formData.notifyType && this.formData.componentValue) {
								this.getMessageTime(
									this.formData.componentValue,
									this.formData.notifyType
								)
							}
						})
					}) // 获取跟踪审查信息
			}
		}
	},
	methods: {
		handleChangeCheck(val) {
			this.formData.notifyType = val
			if (val === 100) {
				this.formData.notifyFrequency = this.formData.notifyFrequency || 2
			}
			if (val && this.formData.componentValue) {
				this.getMessageTime(this.formData.componentValue, val)
			} else {
				this.formData.messageDate = ""
			}
		},
		// 更改其他月份
		handleChangeOther(val) {
			if (val && this.formData.componentValue) {
				this.getMessageTime(this.formData.componentValue, 100)
			} else {
				this.formData.messageDate = ""
			}
		},
		// 获取短信通知日期
		getMessageTime(checkTime, month) {
			let date = ""
			let newMonth = month
			if (month === 100) {
				// 其他 月份
				newMonth = this.formData.notifyFrequency || 0
			} else if (month === 1) {
				// 3个月/次
				newMonth = 3
			} else if (month === 2) {
				// 6个月/次
				newMonth = 6
			} else if (month === 3) {
				// 1年/次
				newMonth = 12
			}
			if (checkTime && newMonth) {
				let arr = checkTime.split("-")
				// 月份是0-11，需要减1来计算。
				arr = arr.map(item => Number(item.trim()))
				if (arr.length === 3 && arr[2] !== 0) {
					const arrDate = new Date(arr.join("-"))
					// 短信通知日期 需要提前一个月通知，要减掉1；
					const timeD = moment(arrDate)
						.add(newMonth - 1, "months")
						.format("YYYY-MM-DD")
					const timeE = moment(arrDate)
						.add(newMonth - 1, "months")
						.format("YYYY-MM-DD 10:00:00")
					const messageTime = new Date(timeE).getTime() // 短信通知日期 时间戳
					// 伦理批件有效期
					const timeEthics = moment(arrDate)
						.add(newMonth, "months")
						.format("YYYY-MM-DD 23:59:59")
					const EthicsTime = new Date(timeEthics).getTime() // 伦理批件有效期 时间戳
					// 当天日期
					this.nowDate = new Date().getTime()
					if (messageTime >= this.nowDate) {
						// 如果发送信息的日期在今天之后，则提示 发送日期
						date = timeD
						this.tip = "提醒日早10:00会以短信形式通知项目内申办方角色"
						this.color = "#666"
					} else {
						// 如果发送信息的日期在今天之前
						if (EthicsTime >= this.nowDate) {
							// 如果发送信息的日期在今天之前，且在伦理批件有效期以内，提示今天
							date = "今天"
						} else {
							// 如果发送信息的日期在今天之前，且在伦理批件有效期以外，提示过期
							date = "伦理批件已过期"
						}
						this.tip = "提交后即刻以短信形式通知项目内申办方角色"
						this.color = "#ff5722"
					}
				}
			} else {
				date = ""
			}
			this.$nextTick(() => {
				this.$set(this.formData, "messageDate", date)
			})
		},
		// 提交
		onOk() {
			this.$refs.supplementaryInfoForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiSetFollowCheck({
							projectId: this.$route.params.projectId,
							componentAlias: "ethic_approval_date",
							...this.formData,
							notifyFrequency:
								this.formData.notifyType === 100
									? this.formData.notifyFrequency
									: undefined
						})
						if (res) {
							this.$Message.success("操作成功！")
							this.$emit("onOk")
						}
						this.loading = false
					})
				}
			})
		},
		// 关闭
		onCancel() {
			this.$emit("onCancel")
		}
	}
}
</script>

<style lang="less">
.supplementary-info-modal {
  width: 800px;
	.supplementary-info-form {
		padding: 10px 20px 0;
	}
	.reason-input {
		width: 500px;
		textarea.ivu-input {
			font-size: 14px;
		}
	}
}
</style>
