<template>
	<div>
		<Modal
			class="register-approval-modal"
			:value="visible"
			:fullscreen="fullscreen"
			:mask-closable="false"
			@on-cancel="onCancel"
			width="1000"
		>
			<div slot="header" class="modal-header">
				<h2>注册信息</h2>
				<a
					href="javascript:;"
					class="fullscreen"
					@click="fullscreen = !fullscreen"
				>
					<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
						<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
						<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
					</Tooltip>
				</a>
			</div>
			<div class="regCon" :style="{ maxHeight: conHeight + 'px' }">
				<Spin fix v-if="loading"></Spin>
				<Form v-if="!isXinhua" ref="form" :model="formData" :rules="rules">
					<div class="title">所属组织</div>
					<Row :gutter="32" class="mrgin0">
						<Col span="12">
							<FormItem label="类型：">
								{{ formData.orgTypeName }}
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem label="全称：">
								{{ formData.orgName }}
							</FormItem>
						</Col>
						<Col span="24">
							<p class="img-label">营业执照</p>
							<Row :gutter="32" v-if="formData.businessLicence.length">
								<Col
									:span="12"
									v-for="(item, index) in formData.businessLicence"
									:key="index"
								>
									<img
										style="width: 100%; height: 210px"
										:src="getPreviewImg(item)"
										class="preview"
										@click="seeOriginPhoto(formData.businessLicence, index)"
									/>
								</Col>
							</Row>
							<div class="upload-btn-wrap" v-else>
								<p>未上传</p>
							</div>
						</Col>
					</Row>
					<div class="title">所属部门</div>
					<Row :gutter="32" class="mrgin0">
						<Col span="12">
							<FormItem label="名称：">
								{{ formData.deptName }}
							</FormItem>
						</Col>
					</Row>
					<div class="title">用户信息</div>
					<Row :gutter="32" class="mrgin0">
						<Col span="12">
							<FormItem label="邮箱地址：">
								{{ formData.userName }}
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem label="真实姓名：">
								{{ formData.realName }}
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem label="性别：">
								{{ formData.sexName }}
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem label="手机号：">
								{{ formData.phone }}
							</FormItem>
						</Col>
						<Col span="24">
							<FormItem label="用户角色：">
								{{
									formData.roleNameList
										? formData.roleNameList.join("；")
										: "--"
								}}
							</FormItem>
						</Col>
						<Col span="24">
							<hr />
						</Col>
					</Row>
					<Row :gutter="32" class="mrgin0">
						<Col span="8">
							<p class="img-label">身份证头像面</p>
							<img
								style="width: 100%; height: 210px"
								:src="getPreviewImg(formData.identityBack)"
								class="preview"
								v-if="formData.identityBack"
								@click="seeOriginPhoto([formData.identityBack])"
							/>
							<div class="upload-btn-wrap" v-else>
								<p>未上传</p>
							</div>
						</Col>
						<Col span="8">
							<p class="img-label">身份证国徽面</p>
							<img
								style="width: 100%; height: 210px"
								:src="getPreviewImg(formData.identityFront)"
								class="preview"
								v-if="formData.identityFront"
								@click="seeOriginPhoto([formData.identityFront])"
							/>
							<div class="upload-btn-wrap" v-else>
								<p>未上传</p>
							</div>
						</Col>
						<Col span="8">
							<p class="img-label">单位委托书</p>
							<img
								style="width: 100%; height: 210px"
								:src="getPreviewImg(formData.businessProxy)"
								class="preview"
								v-if="formData.businessProxy"
								@click="seeOriginPhoto([formData.businessProxy])"
							/>
							<div class="upload-btn-wrap" v-else>
								<p>未上传</p>
							</div>
						</Col>
					</Row>
					<Row>
						<Col span="24">
							<FormItem prop="status" label="是否同意：">
								<RadioGroup v-model="formData.status">
									<Radio label="1">
										<span>是</span>
									</Radio>
									<Radio label="-1">
										<span>否</span>
									</Radio>
								</RadioGroup>
							</FormItem>
						</Col>
						<Col span="24">
							<FormItem prop="reason" label="备注">
								<Input
									type="textarea"
									:rows="5"
									v-model="formData.reason"
									placeholder="请输入备注信息"
								/>
							</FormItem>
						</Col>
					</Row>
				</Form>
				<Form v-else ref="xinhuaform" :model="formData" :rules="rules">
					<Row :gutter="60">
						<Col span="12">
							<FormItem prop="orgType" label="所属组织">
								{{ formData.orgName || "--" }}
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="deptId" label="所属部门">
								{{ departmentName }}
							</FormItem>
						</Col>
					</Row>
					<Row :gutter="60">
						<Col span="12">
							<FormItem prop="username" label="用户名">
								{{ formData.userName }}
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="realName" label="真实姓名">
								{{ formData.realName }}
							</FormItem>
						</Col>
					</Row>
					<Row :gutter="60">
						<Col span="12">
							<FormItem prop="phone" label="手机号">
								{{ formData.phone }}
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="sex" label="性别">
								{{ formData.sexName }}
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="roles" label="用户角色">
								{{
									formData.roleNameList && formData.roleNameList.length
										? formData.roleNameList.join(",")
										: "--"
								}}
							</FormItem>
						</Col>
					</Row>
					<Row>
						<Col span="24">
							<FormItem prop="status" label="是否同意：">
								<RadioGroup v-model="formData.status" @on-change="changeStatus">
									<Radio label="1">
										<span>是</span>
									</Radio>
									<Radio label="-1">
										<span>否</span>
									</Radio>
								</RadioGroup>
							</FormItem>
						</Col>
						<Col span="24">
							<FormItem
								:required="formData.status === '-1' ? true : false"
								label="备注"
							>
								<Input
									type="textarea"
									:rows="5"
									v-model="formData.reason"
									placeholder="请输入备注信息"
								/>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>
			<div slot="footer">
				<Button @click="onCancel">取消</Button>
				<Button type="primary" :loading="loading" @click="onOK">确定 </Button>
			</div>
		</Modal>
		<previewImages
			:visible="preImgVisible"
			:images="images"
			:selIndex="selIndex"
			@closed="preImgVisible = false"
		>
		</previewImages>
	</div>
</template>
<script>
import publicApi from "@/api/public"
import { getRegDetail, audit } from "@/api/user/register"
import previewImages from "./components/previewImages.vue"
import { mapState } from "vuex"

const { publicGetDepartmentList } = publicApi
export default {
	name: "RegisterApproval",
	props: ["visible", "id"],
	components: {
		previewImages
	},
	data() {
		return {
			loading: false,
			fullscreen: false,
			formData: {
				status: "",
				businessLicence: [],
				roleNameList: []
			},
			rules: {
				status: {
					required: true,
					message: "是否同意不能为空",
					trigger: "change"
				},
				reason: {
					required: true,
					message: "请填写备注",
					trigger: "blur"
				}
			},
			deptLists: [],
			preImgVisible: false,
			images: [],
			selIndex: 0
		}
	},
	computed: {
		...mapState({
			contentHeight: state => state.contentHeight - 150
		}),
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		},
		conHeight() {
			return this.fullscreen
				? document.body.clientHeight - 145
				: this.contentHeight
		},
		departmentName() {
			if (this.formData.deptId) {
				if (this.deptLists && this.deptLists.length) {
					const obj = this.deptLists.find(
						item => item.id === this.formData.deptId
					)
					if (obj) {
						return obj.name
					}
					return "--"
				}
				return "--"
			}
			return "--"
		}
	},
	watch: {
		visible() {
			if (this.visible && this.id) {
				if (this.isXinhua) {
					this.$refs.xinhuaform.resetFields()
					this.getXinhua()
				} else {
					this.$refs.form.resetFields()
				}
				this.$asyncDo(async () => {
					this.loading = true
					const res = await getRegDetail({ taskId: String(this.id) })
					if (res && res.data) {
						this.formData = {
							...res.data,
							status: String(this.formData.status),
							businessLicence:
								res.data.businessLicence && res.data.businessLicence.split(",")
						}
					}
					this.loading = false
				})
			}
		}
	},
	methods: {
		async getXinhua() {
			const res = await publicGetDepartmentList({
				// 获取医院下 部门，默认先传1.
				orgId: 1
			})
			if (res) {
				this.deptLists = res.data || []
			}
		},
		changeStatus(val) {
			this.$set(this.formData, "status", val)
		},
		onOK() {
			const form = this.isXinhua ? "xinhuaform" : "form"
			this.$refs[form].validate(valid => {
				if (valid) {
					if (this.formData.status === "-1" && !this.formData.reason) {
						this.$Message.error("审批不同意，请填写备注")
						return
					}
					this.$asyncDo(async () => {
						this.loading = true
						const res = await audit({
							taskId: this.id,
							status: this.formData.status,
							reason: this.formData.reason
						})
						if (res) {
							this.$Message.success("审批成功")
							this.$emit("onOk")
						}
						this.loading = false
					})
				}
			})
		},
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		},
		seeOriginPhoto(images = [], index = 0) {
			this.preImgVisible = true
			this.images = [...images]
			this.selIndex = index
		}
	}
}
</script>
<style lang="less">
.register-approval-modal {
	.regCon {
		overflow: auto;
	}

	.title {
		font-size: 18px;
		font-weight: bold;
		height: 40px;
		line-height: 40px;
	}

	.mrgin0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.img-label {
		font-size: 14px;
		color: #515a6e;
		padding: 10px 12px 10px 0;
	}
	.preview {
		width: 100%;
		cursor: pointer;
	}
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
</style>
