<template>
	<Modal
		class="add-team-user-wrap"
		title="添加成员"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div style="padding-bottom: 0">
			<div class="curr-role">
				当前角色：<span v-if="role">{{ role.roleName }}</span>
			</div>
			<Input
				placeholder="请输入您要查询的人员名称"
				search
				@on-search="searchHandle"
				v-model="realName"
				style="margin-bottom: 15px"
			/>
			<div>
				<Checkbox
					:disabled="!assignRoleUsers.length || isSearch"
					:indeterminate="indeterminate"
					v-model="checkAll"
					@on-change="handleCheckAll"
					><span style="font-weight: bold; line-height: 30px" v-if="role">{{
						role.roleName
					}}</span></Checkbox
				>
			</div>

			<div class="user-list" v-if="assignRoleUsers.length">
				<CheckboxGroup
					v-model="userIds"
					@on-change="val => checkAllGroupChange(val)"
				>
					<div
						v-for="(user, index) in assignRoleUsers"
						:key="user.userId"
						class="users"
					>
						<Checkbox :label="user.userId"
							><span>{{ user.userName }}</span></Checkbox
						>
					</div>
				</CheckboxGroup>
			</div>
			<p v-else style="padding-left: 20px">暂无</p>
			<p style="font-weight: bold; line-height: 30px">其他</p>
			<div class="user-list" v-if="otherRoleUsers.length">
				<CheckboxGroup v-model="otherUserIds">
					<div
						v-for="(user, index) in otherRoleUsers"
						:key="user.userId"
						class="users"
					>
						<Checkbox :label="user.userId"
							><span>{{ user.userName }}</span></Checkbox
						>
					</div>
				</CheckboxGroup>
			</div>
			<p v-else style="padding-left: 20px">暂无</p>
		</div>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>
<script>
import api from "@/api/project/team"
import { mapState } from "vuex"

const { addTeamUsers, getUserAndOthers } = api
export default {
	props: ["visible", "role"],
	data() {
		return {
			loading: false,
			defaultList: [],
			defaultOtherList: [],
			assignRoleUsers: [],
			otherRoleUsers: [],
			userIds: [],
			otherUserIds: [],
			allUserIds: [],
			realName: "",
			projectId: this.$route.params.projectId,
			indeterminate: false,
			checkAll: false,
			isSearch: false
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 80
		})
	},
	watch: {
		visible() {
			if (this.visible) {
				this.realName = ""
				this.checkAll = false
				this.indeterminate = false
				this.isSearch = false
				this.userIds = []
				this.otherUserIds = []
				this.allUserIds = []
				this.getuserList()
			}
		}
	},
	methods: {
		handleCheckAll(val) {
			if (this.indeterminate) {
				this.$set(this, "checkAll", true)
			}
			this.indeterminate = false
			if (this.checkAll) {
				const ids = this.assignRoleUsers.map(item => item.userId)
				this.$set(this, "userIds", [...ids])
			} else {
				this.$set(this, "userIds", [])
			}
		},
		checkAllGroupChange(data) {
			if (!this.assignRoleUsers.length) {
				this.indeterminate = false
				this.$set(this, "checkAll", false)
				return
			}
			const ids = this.assignRoleUsers.map(item => item.userId)

			if (data.length >= ids.length) {
				if (this.isInclude(ids, data)) {
					// 搜索结果全部包含在已选中里
					this.indeterminate = false
					this.$set(this, "checkAll", true)
				} else if (this.isNoInclude(ids, data)) {
					// 搜索结果全部 不包含在已选中里
					this.indeterminate = false
					this.$set(this, "checkAll", false)
				} else {
					// 搜索结果 部分 包含在已选中里
					this.indeterminate = true
					this.$set(this, "checkAll", false)
				}
			} else if (data.length > 0) {
				if (this.isNoInclude(ids, data)) {
					// 搜索结果全部 不包含在已选中里
					this.indeterminate = false
					this.$set(this, "checkAll", false)
				} else {
					// 搜索结果 部分 包含在已选中里
					this.indeterminate = true
					this.$set(this, "checkAll", false)
				}
			} else {
				this.indeterminate = false
				this.$set(this, "checkAll", false)
			}
		},
		// littleArr 是否全部包含在 bigArr里
		isInclude(littleArr, bigArr) {
			return littleArr.every(item => bigArr.some(sub => sub === item))
		},
		// littleArr 是否全部 不包含在 bigArr里
		isNoInclude(littleArr, bigArr) {
			const arr = littleArr.filter(item => bigArr.includes(item))
			return !arr.length
		},
		getuserList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getUserAndOthers({
					roleId: this.role.roleId
				})
				if (res) {
					this.assignRoleUsers = res.data.assignRoleUsers || []
					this.otherRoleUsers = res.data.otherRoleUsers || []
					this.allUserIds = this.role.userList.length
						? this.role.userList.map(item => item.id)
						: []
					this.$nextTick(() => {
						if (this.allUserIds.length) {
							this.userIds = this.allUserIds.filter(id => {
								if (this.assignRoleUsers.find(item => item.userId === id)) {
									return true
								}
								return false
							})
							this.otherUserIds = this.allUserIds.filter(id => {
								if (this.otherRoleUsers.find(item => item.userId === id)) {
									return true
								}
								return false
							})
							this.checkAllGroupChange(this.userIds)
						}
					})
				}
				this.defaultList = [...this.assignRoleUsers]
				this.defaultOtherList = [...this.otherRoleUsers]
				this.loading = false
			})
		},
		searchHandle() {
			this.assignRoleUsers = this.defaultList.filter(o =>
				o.userName.match(this.realName)
			)
			const ids = this.assignRoleUsers
				.filter(item => {
					if (this.userIds.find(id => id === item.userId)) {
						return true
					}
					return false
				})
				.map(item => item.userId)
			this.otherRoleUsers = this.defaultOtherList.filter(o =>
				o.userName.match(this.realName)
			)
			if (!this.realName) {
				this.isSearch = false
			} else {
				this.isSearch = true
			}
			this.checkAllGroupChange(ids)
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await addTeamUsers({
					projectId: this.projectId,
					userIds: [...this.userIds, ...this.otherUserIds],
					roleId: this.role.roleId
				})
				if (res) {
					this.$emit("onOk")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			})
		}
	}
}
</script>
<style lang="less" scoped>
.curr-role {
	margin-bottom: 15px;

	span {
		color: #2d8cf0;
	}
}

.user-list {
	overflow: auto;
}

.users {
	margin-bottom: 15px;
	padding-left: 15px;
}
</style>
