<template>
	<div class="content-wrap">
		<!--研究型病房入驻情况报表-->
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="项目类型">
				<Select
					v-model="searchParams.type"
					:transfer="true"
					placeholder="请选择类型"
				>
          <Option value="IIT">研究者发起的研究IIT</Option>
          <Option value="IST">申办方发起的研究IST</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="创建日期">
				<DatePicker
					type="daterange"
					placeholder="请选择"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button @click="exportData">导出研究型病房入驻情况</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="data"
      :page="page"
			ref="table"
		>
		</CtmsDataGrid>
	</div>
</template>

<script>
import fundsApi from "@/api/project/funds"
import { mapState } from "vuex"

const { apiGetDeparts, apiGetReportFundsListPage } = fundsApi

export default {
	name: "WardCheckIn",
	data() {
		return {
			loading: false,
			entry: "",
			updateId: "",
			fundsType: "", // 1 入账 / 2 出账
			// 科室
			departList: [],
			// 项目基本信息
			projectInfo: {},
			times: [],
			searchParams: {
				approvedStatus: "",
				type: "",
				arrivalTimeStart: this.times ? this.times[0] : "",
				arrivalTimeEnd: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
				approvedStatus: "",
				type: "",
				arrivalTimeStart: this.times ? this.times[0] : "",
				arrivalTimeEnd: this.times ? this.times[1] : ""
			},
			data: [
        {"a":"质谱多组学检测及指纹图谱分析在卵巢癌诊治中的应用实践","b":"在研阶段","c":"进行中","d":"KS23167","e":"2023.4.3","f":"KS23167","g":"2023.4.3","h":"否","i":"","j":"","k":"","l":"","m":"","n":"","o":"","p":"","q":"2022.10.20","r":50,"s":"2023.4.4","t":"2023.4.4","u":"2023.4.4","v":"NA","w":4,"x":1168,"y":1168},
      ],
			columns: [
				{
					title: "项目名称",
					key: "a",
					minWidth: 250
				},
				{
					title: "项目等级",
					key: "b",
					minWidth: 140,
				},
				{
					title: "项目具体阶段",
					key: "c",
					minWidth: 120
				},
				{
					title: "访视周期",
					key: "d",
					minWidth: 130
				},
				{
					title: "生物样本拟采集类型",
					key: "e",
					minWidth: 120
				},
				{
					title: "项目进度（研究病房入驻信息专用信息）",
					key: "f",
					minWidth: 180
				},
				{
					title: "责任医生",
					key: "g",
					minWidth: 150
				},
				{
					title: "责任护士",
					key: "h",
					minWidth: 130
				},
				{
					title: "CRU对接人员（IIT项目填写）",
					key: "i",
					minWidth: 120
				},
				{
					title: "质控对接人（IST项目填写）",
					key: "j",
					minWidth: 150
				},
				{
					title: "项目累计入组人数",
					key: "k",
					minWidth: 150
				},
				{
					title: "病房内累计访视人次",
					key: "l",
					minWidth: 120
				},
				{
					title: "累计住院天数",
					key: "m",
					minWidth: 120
				},
				{
					title: "生物样本累计采集例次",
					key: "n",
					minWidth: 120
				},
				{
					title: "本院SAE例次",
					key: "o",
					minWidth: 150
				},
				{
					title: "已质控次数",
					key: "p",
					minWidth: 140,
					slot: "information"
				},
				{
					title: "上次质控时间",
					key: "q",
					minWidth: 140
				},
				{
					title: "上报方案违背/偏离例次",
					key: "r",
					minWidth: 140
				}
			],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 140
		})
	},
	mounted() {
		this.getPublicData()
		// this.initData()
	},
	methods: {
		getPublicData() {
			// 获取科室
			this.$asyncDo(async () => {
				const res = await apiGetDeparts()
				if (res) {
					this.departList = res.data || []
				}
			})
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetReportFundsListPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
				})
				if (res) {
					this.data = res.data.list || []
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		exportData() {
			/*
			 * type 费用类型：IST、申办方发起的研究IST IIT、研究者发起的研究IIT
			 * arrivalTimeStart 创建时间-开始
			 * arrivalTimeEnd 创建时间-结束
			 * */
			const type = this.searchParams.type || ""
			const start = this.searchParams.arrivalTimeStart || ""
			const end = this.searchParams.arrivalTimeEnd || ""
			const approvedStatus =
				this.searchParams.approvedStatus === undefined
					? ""
					: this.searchParams.approvedStatus
			const url = `${this.$baseUrl}/report/outlay/export?classification=1&type=${type}&arrivalTimeStart=${start}&arrivalTimeEnd=${end}&approvedStatus=${approvedStatus}&token=${this.$store.state.user.token}`
			window.open(url)
		},
		// 重置
		reset() {
			this.times = []
			this.searchParams.type = ""
			this.searchParams.approvedStatus = ""
			this.searchHandle()
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		},
		searchHandle() {
			this.searchParams.arrivalTimeStart = this.times.length
				? this.times[0]
				: ""
			this.searchParams.arrivalTimeEnd = this.times.length ? this.times[1] : ""
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initData()
			})
		}
	}
}
</script>
