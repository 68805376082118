<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<Tree
			:data="treeData"
			@on-select-change="onSelectChange"
			class="left-tree"
		></Tree>
		<div class="right-list">
			<CtmsAction>
				<Button
					v-if="
						isChild &&
						systemActionPermissions.indexOf('btn_setting_dictionary_add') >
							-1 &&
						isShowAdd
					"
					@click="handleAddShow"
				>
					新增
				</Button>
				<Button
					v-if="
						systemActionPermissions.indexOf('btn_setting_dictionary_add') > -1
					"
					@click="handleSort"
				>
					排序
				</Button>
				<div class="custom-search-wrapper">
					<span>状态：</span>
					<Select
						v-model="searchParams.isDel"
						:transfer="true"
						placeholder="请选择状态"
						clearable
						style="width: 200px"
						@on-change="getSearchListData"
					>
						<Option :value="0">启用</Option>
						<Option :value="1">禁用</Option>
					</Select>
				</div>
			</CtmsAction>
			<CtmsDataGrid
				:height="dataGridHeight"
				:columns="allColumns"
				:data="searchListData"
				:page="false"
			></CtmsDataGrid>
		</div>
		<Add
			:visible="addVisible"
			:parentNode="parentNode"
			:isShowAdd="isShowAdd"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:updateId="updateId"
		></Add>
		<ViewDetail
			:visible="viewVisible"
			@onCancel="viewVisible = false"
			:viewId="viewId"
		></ViewDetail>
		<!--排序-->
		<DictionaryOrder
			:visible="addSortVisible"
			@onCancel="addSortVisible = false"
			@onOk="handleProcessSortOk"
			:dictionaryData="searchListData"
		></DictionaryOrder>
	</div>
</template>

<script>
import api from "@/api/setting/dictionary"
import { transformTreeData } from "@/utils/util"
import Add from "./Add.vue"
import ViewDetail from "./ViewDetail.vue"
import { mapState } from "vuex"
import DictionaryOrder from "./dictionaryOrder.vue"

const { apiGetPage, apiDelete } = api

const columns = [
	{
		title: "名称",
		key: "name",
		minWidth: 250
	},
	{
		title: "简称/值",
		key: "obj.nickname",
		minWidth: 250
	},
	{
		title: "状态",
		render: (h, params) =>
			h("span", {}, params.row.obj.isDel ? "禁用" : "启用"),
		minWidth: 80
	}
]

export default {
	name: "index",
	components: {
		Add,
		ViewDetail,
		DictionaryOrder
	},
	data() {
		return {
			treeData: [],
			listData: [],
			searchListData: [],
			columns,
			allColumns: [],
			actionColumn: {
				title: "操作",
				key: "action",
				width: 170,
				fixed: "right",
				renderButton: params => {
					const actionList = [
						{
							label: "查看",
							on: {
								click: this.handleViewShow
							}
						},
						{
							label: "修改",
							on: {
								click: this.handleUpdateShow
							}
						},
						{
							label: params.row.obj.isDel ? "启用" : "禁用",
							on: {
								click: this.handleSwitchState
							},
							confirm: {
								title: `您确认${params.row.obj.isDel ? "启用" : "禁用"}这条数据吗？`
							}
						}
					]
					if (
						this.systemActionPermissions.indexOf(
							"btn_setting_dictionary_add"
						) === -1
					) {
						actionList[1] = null
						actionList[2] = null
					}
					if (
						this.parentNode &&
						(this.parentNode.id === "24" ||
							this.parentNode.id.indexOf("24") === 0)
					) {
						// 流程类型，不允许禁用。
						actionList[2] = null
					}
					return actionList.filter(item => !!item)
				}
			},
			loading: false,
			isChild: true, // 控制列表页面是否有操作按钮
			addVisible: false,
			addSortVisible: false,
			viewVisible: false,
			parentNode: {},
			updateId: "",
			viewId: "",
			searchParams: {
				isDel: 0
			}
		}
	},
	created() {
		this.initList()
		this.switchActionColumn()
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 50
		}),
		...mapState("permission", ["systemActionPermissions"]),
		isShowAdd() {
			if (this.parentNode && this.parentNode.id) {
				if (this.parentNode.id === "24" || this.parentNode.id === "25") {
					// 流程类型和项目阶段，不允许新增。
					return false
				}
				const num = this.parentNode.id.indexOf("24")
				const num2 = this.parentNode.id.indexOf("25")
				if (num === 0 || num2 === 0) {
					// 表示id是以24 或 25 开头的，不显示新增
					return false
				}
				return true
			}
			return true
		}
	},
	watch: {
		isChild() {
			this.switchActionColumn()
		}
	},
	methods: {
		handleProcessSortOk() {
			this.addSortVisible = false
			this.initList("index")
		},
		initList(type) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage()
				if (res) {
					const treeData = transformTreeData(res.data)
					if (type === "index") {
						// 记录上次点击的节点
						this.getCurrentNode(treeData)
					} else {
						treeData[0].selected = true
						this.selectTreeNode(treeData[0])
					}
					this.treeData = treeData
				}
				this.loading = false
			})
		},
		getCurrentNode(arr) {
			arr.forEach(item => {
				if (item.id === this.parentNode.id) {
					item.selected = true
					this.selectTreeNode(item)
				} else if (item.children && item.children.length) {
					this.getCurrentNode(item.children)
				}
			})
		},
		// 选中节点时的操作
		selectTreeNode(node) {
			this.parentNode = node
			this.listData = node.children
			this.getSearchListData()
		},
		onSelectChange(selectArr) {
			if (selectArr.length) {
				this.parentNode = selectArr[0]
				this.selectTreeNode(selectArr[0])
				this.isChild = true
			} else {
				this.listData = this.treeData
				this.isChild = false
			}
		},
		getSearchListData() {
			// 当不选状态时
			if (this.searchParams.isDel === undefined) {
				this.searchListData = this.listData
			} else {
				this.searchListData = this.listData.filter(
					item => item.obj.isDel === this.searchParams.isDel
				)
			}
		},
		// 新增显示
		handleAddShow() {
			this.updateId = ""
			this.addVisible = true
		},
		// 排序
		handleSort() {
			this.addSortVisible = true
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList("index")
			if (Number(this.parentNode.id) === 25) {
				this.$store.dispatch("user/getProjectStepInfo")
			}
			if (Number(this.parentNode.id) === 24) {
				this.$store.dispatch("user/getProcessList")
			}
		},
		// 查看显示
		handleViewShow({ row }) {
			this.viewId = row.id
			this.viewVisible = true
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.updateId = row.id
			this.addVisible = true
		},
		// 禁用
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiDelete({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList("index")
					if (Number(this.parentNode.id) === 25) {
						this.$store.dispatch("user/getProjectStepInfo")
					}
					if (Number(this.parentNode.id) === 24) {
						this.$store.dispatch("user/getProcessList")
					}
				} else {
					this.loading = false
				}
			})
		},
		// 是否显示操作列
		switchActionColumn() {
			if (this.isChild) {
				this.allColumns = [...this.columns, this.actionColumn]
			} else {
				this.allColumns = [...this.columns]
			}
		}
	}
}
</script>

<style scoped>
.right-list {
	position: absolute;
	left: 230px;
	top: 0;
	height: 100%;
	width: calc(100% - 230px);
	padding-left: 16px;
}
.custom-search-wrapper {
	display: inline-block;
	float: right;
	padding-right: 10px;
}
</style>
