<template>
	<Modal
		:value="visible"
		class="ethical-meeting-add-modal"
		width="1200"
		:styles="{ top: fullscreen ? 0 : '30px' }"
		:fullscreen="fullscreen"
		@on-cancel="cancel"
		:loading="modalLoading"
		:mask-closable="false"
		style="position: relative"
	>
		<Spin v-if="modalLoading" fix></Spin>
		<div slot="header" class="modal-header">
			<h2>{{ title }}</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<Form
			:model="formData"
			ref="ethicalForm"
			label-position="top"
			:rules="rules"
		>
			<Row :gutter="32">
				<Col
					span="12"
					:style="`max-height:${gridHeight + 65}px;overflow-y: auto;`"
				>
					<template v-if="updateId">
						<FormItem label="发起人" prop="initiatorId">
							<Select
								v-model="formData.initiatorId"
								:transfer="true"
								:disabled="entry === 'view'"
								filterable
								clearable
								placeholder="请选择发起人"
							>
								<!--@on-query-change="onSponsorQueryChange"-->
								<Option
									:value="staff.id"
									v-for="staff in sponsorStaffList"
									:key="staff.id"
									:label="staff.realName"
								></Option>
							</Select>
						</FormItem>
					</template>

					<FormItem label="会议名称" prop="name">
						<Input
							v-model.trim="formData.name"
							:maxlength="20"
							:disabled="entry === 'view'"
							placeholder="请输入会议名称"
						/>
					</FormItem>
					<Row :gutter="16">
						<Col span="12">
							<FormItem label="会议时间" prop="meetDate">
								<DatePicker
									type="date"
									:disabled="entry === 'view'"
									placeholder="请选择会议日期"
									format="yyyy-MM-dd"
									v-model="formData.meetDate"
									style="width: 100%"
								></DatePicker>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem label="  " prop="meetTimes" class="empty-label">
								<TimePicker
									type="timerange"
									placeholder="请选择会议时间"
									:disabled="entry === 'view'"
									format="HH:mm"
									:steps="[1, 15]"
									v-model="formData.meetTimes"
									style="width: 100%"
								></TimePicker>
							</FormItem>
						</Col>
					</Row>
					<FormItem label="参会人员" prop="participants">
						<Select
							v-model="formData.participants"
							:transfer="true"
							:disabled="entry === 'view'"
							filterable
							multiple
							@on-query-change="onQueryChange"
							placeholder="请选择参会人员"
						>
							<Option
								:value="staff.id"
								v-for="staff in optionStaffList"
								:key="staff.id"
								:label="staff.realName"
							></Option>
						</Select>
					</FormItem>
					<FormItem label="会议地点">
						<Input
							v-model.trim="formData.address"
							:disabled="entry === 'view'"
							:maxlength="20"
							placeholder="请输入会议地点"
						/>
					</FormItem>
					<FormItem label="会议附件">
						<Upload
							action="/"
							ref="upload"
							multiple
							:disabled="entry === 'view'"
							:before-upload="file => handleBeforeUpload(file)"
							:on-success="onSuccess"
							:on-error="onError"
							:on-remove="onRemove"
							:show-upload-list="false"
						>
							<!--:default-file-list="fileList"-->
							<Button :loading="uploadLoading" :disabled="entry === 'view'">{{
								uploadLoading ? "上传中..." : "上传附件"
							}}</Button>
						</Upload>
						<div v-if="fileList && fileList.length" style="padding-top: 10px">
							<p
								v-for="(item, index) in fileList"
								:key="index"
								class="file-item"
							>
								<span class="file-item-name">{{ item.name }}</span>
								<span class="file-item-actions">
									<Icon
										type="ios-cloud-download-outline"
										@click="handleDownload(item)"
										class="download-icon"
										title="下载"
										:size="24"
									/>
									<Icon
										type="ios-close"
										v-if="entry !== 'view'"
										@click="onRemove(item)"
										class="download-icon"
										title="删除"
										:size="24"
									/>
								</span>
							</p>
						</div>
					</FormItem>
					<FormItem label="会议描述">
						<Input
							type="textarea"
							:maxlength="100"
							:disabled="entry === 'view'"
							v-model.trim="formData.details"
							placeholder="请输入会议描述"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="上会项目">
						<Button
							type="primary"
							:disabled="entry === 'view'"
							@click="handleMeetingPro"
							>设置上会项目</Button
						>
						<CtmsDataGrid
							:columns="columns"
							:height="gridHeight"
							:data="projectList"
							:page="false"
						>
						</CtmsDataGrid>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="cancel">{{
				entry === "view" ? "关闭" : "取消"
			}}</Button>
			<Button
				type="primary"
				@click="onOk"
				v-if="entry !== 'view'"
				:loading="loading"
				>确定</Button
			>
		</div>
		<!--设置上会项目-->
		<SetMeetingProjects
			:visible="setMeetingVisible"
			:projectList="projectList"
			@onOk="handleOnOk"
		></SetMeetingProjects>
		<DocumentPreview
			:visible="documentPreviewVisible"
			:documentSrc="documentSrc"
			:id="'trainDocumentPreview'"
			@onCancel="documentPreviewVisible = false"
		></DocumentPreview>
	</Modal>
</template>

<script>
import api from "@/api/ethicalMeeting/index"
import { formDataToMD5 } from "@/utils/util"
import publicApi from "@/api/public"
import fundsApi from "@/api/project/funds"
import docType from "@/config/docType"
import SetMeetingProjects from "./SetMeetingProjects.vue"
import DocumentPreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"
import { mapState } from "vuex"
import moment from "moment"

const { publicGetuserList } = publicApi
const { getResearchUers } = fundsApi
const { addEthicalMeet, getMeetDetail, editEthicalMeet, uploadFile } = api
export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		updateId: {
			type: String,
			default: ""
		},
		entry: {
			type: String,
			default: ""
		}
	},
	components: {
		SetMeetingProjects,
		DocumentPreview
	},
	data() {
		return {
			fullscreen: false,
			title: "",
			loading: false,
			searchLoading: false,
			modalLoading: false,
			uploadLoading: false,
			documentPreviewVisible: false,
			documentSrc: "",
			setMeetingVisible: false,
			fileList: [],
			fileIdsList: [],
			formData: {
				participants: [],
				meetTimes: []
			},
			rules: {
				name: {
					required: true,
					message: "会议名称不能为空",
					trigger: "blur"
				},
				meetDate: {
					type: "date",
					required: true,
					message: "会议日期不能为空",
					trigger: "blur change"
				},
				meetTimes: [
					{
						required: true,
						type: "array",
						message: "请选择",
						trigger: "blur,change"
					},
					{ validator: this.validateTimes, trigger: "change" }
				],
				participants: {
					type: "array",
					required: true,
					message: "参会人员不能为空",
					trigger: "blur change"
				},
				initiatorId: {
					required: true,
					message: "发起人不能为空",
					trigger: "blur change"
				}
			},
			staffList: [],
			optionStaffList: [],
			allStaffList: [],
			sponsorStaffList: [],
			ethicalUserList: [], // 伦理委员
			projectList: [],
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 150,
					render: (h, params) => h("span", {}, params.row.projectName || "-")
				},
				{
					title: "主要研究者",
					key: "pis",
					minWidth: 120,
					render: (h, params) => h("span", {}, params.row.pis || "-")
				}
			]
		}
	},
	computed: {
		...mapState("permission", ["systemActionPermissions"]),
		...mapState("user", ["info"]),
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 230
				: this.dataGridHeight
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.title = "新增会议"
				this.fullscreen = false
				this.$refs.ethicalForm.resetFields()
				this.formData = {
					participants: [],
					meetTimes: []
				}
				this.fileList = []
				this.fileIdsList = []
				this.projectList = []
				this.allStaffList = []
				this.staffList = []
				this.optionStaffList = []
				this.sponsorStaffList = []
				this.ethicalUserList = []
				this.getPublicData()
				if (this.updateId) {
					this.title = this.entry === "view" ? "查看会议" : "编辑会议"
					this.init()
				}
			}
		}
	},
	methods: {
		async init() {
			this.modalLoading = true
			const res = await getMeetDetail(this.updateId)
			if (res) {
				const sDate = res.data.startTime.split(" ")
				const eDate = res.data.endTime.split(" ")
				this.formData = {
					...res.data,
					participants: res.data.participantVOs.map(item => item.participantId),
					meetDate: sDate[0],
					meetTimes: [sDate[1], eDate[1]]
				}
				if (this.formData.initiatorId === "1") {
					this.allStaffList = [
						...this.staffList,
						{
							id: "1",
							realName: "超级管理员"
						}
					]
				}
				if (this.formData.initiatorId === "2") {
					this.allStaffList = [
						...this.staffList,
						{
							id: "2",
							realName: "系统管理员"
						}
					]
				}

				this.sponsorStaffList = [...this.allStaffList]
				this.projectList = res.data.projectVOs || []
				this.fileList = res.data.attachments.filter(item => !!item)
				this.fileIdsList = this.fileList.map(item => item.id)
			}
			this.modalLoading = false
		},
		// 发起人 搜索过滤
		onSponsorQueryChange(query) {
			if (query !== "") {
				this.sponsorStaffList = this.allStaffList.filter(
					item => item.realName.toLowerCase().indexOf(query.toLowerCase()) > -1
				)
			} else {
				this.sponsorStaffList = [...this.allStaffList]
			}
		},
		handleSelectSponsor(val) {
			console.log("select val", val)
		},
		handleChangeSponsor(val) {
			console.log("change val", val)
		},
		handleOpenChangeSponsor(val) {
			console.log("open change val", val)
		},
		// 参会人员 搜索过滤
		onQueryChange(query) {
			if (query !== "") {
				this.optionStaffList = this.staffList.filter(
					item => item.realName.toLowerCase().indexOf(query.toLowerCase()) > -1
				)
			} else {
				this.optionStaffList = [...this.staffList]
			}
		},
		// 验证 会议时间
		validateTimes(rule, value, callback) {
			if (!value[0] || !value[1]) {
				callback(new Error("请选择会议时间"))
			} else {
				callback()
			}
		},
		getPublicData() {
			// 获取数据字典
			this.$asyncDo(async () => {
				const res = await publicGetuserList()
				if (res) {
					this.staffList = [...res.data]
					this.optionStaffList = [...this.staffList]
					this.allStaffList = [...res.data]
					this.sponsorStaffList = [...this.allStaffList]
				}
				// 获取伦理委员
				const ret = await getResearchUers({
					roleId: 5
				})
				if (ret) {
					this.ethicalUserList = ret.data || []
					if (!this.updateId) {
						this.formData.participants = this.ethicalUserList.map(
							item => item.id
						)
					}
				}
			})
		},
		// 设置上会项目
		handleMeetingPro() {
			this.setMeetingVisible = true
		},
		// 设置伦理上会项目 成功
		handleOnOk(meetList) {
			this.projectList = [...meetList]
			this.setMeetingVisible = false
		},
		// 提交
		onOk() {
			this.$refs.ethicalForm.validate(valid => {
				if (valid) {
					if (!this.projectList.length) {
						this.$Modal.confirm({
							title: "提示",
							content: "您还没有设置上会项目，是否确认提交？",
							onOk: () => {
								this.submit()
							}
						})
					} else {
						this.submit()
					}
				}
			})
		},
		submit() {
			this.$asyncDo(async () => {
				let res = false
				const {
					name,
					meetDate,
					meetTimes,
					address,
					details,
					participants,
					initiatorId
				} = this.formData
				const date = moment(meetDate).format("YYYY-MM-DD")
				const params = {
					name,
					address,
					details,
					participants,
					startTime: `${date} ${meetTimes[0]}:00`,
					endTime: `${date} ${meetTimes[1]}:00`,
					attachmentIds: this.fileIdsList || [],
					projectIds: this.projectList.map(item => item.projectId)
				}
				if (this.updateId) {
					// 修改
					res = await editEthicalMeet({
						...params,
						ethicMeetingId: this.updateId,
						initiatorId
					})
				} else {
					// 新增
					res = await addEthicalMeet({
						...params
					})
				}
				if (res) {
					this.$Message.success("操作成功")
					this.$emit("onOk")
				}
			})
		},
		handleBeforeUpload(file) {
			console.log("before upload file", file)
			this.uploadLoading = true
			this.controller = new AbortController()
			const { signal } = this.controller
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, { md5: md5Str }, signal)
			})
			return false
		},
		// 上传文件
		uploadFile(file, params, signal) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				const res = await uploadFile(fd, params, signal)
				if (res) {
					this.$Message.success("上传成功!")
					const newFile = Object.assign(file, { id: res.data })
					this.fileList.push(newFile)
					this.fileIdsList.push(res.data)
				}
				this.uploadLoading = false
			})
		},
		// 文件上传成功后
		onSuccess(res) {
			if (res.code !== 0) {
				this.$Message.error(res.message)
			} else {
				// this.$Message.success('上传成功');
			}
		},
		onError(error) {
			this.$Message.error(error)
		},
		onRemove(file) {
			let delId = ""
			this.fileList = this.fileList.filter(item => {
				if (file.id) {
					delId = file.id
					return item.id !== file.id
				}
				delId = item.uid === file.uid ? item.id : ""
				return item.uid !== file.uid
			})
			this.fileIdsList = this.fileIdsList.filter(item => item !== delId)
		},
		// 下载
		handleDownload(file) {
			window.open(
				`${this.$baseUrl}/document/attachment/download/${file.id}?token=${this.$store.state.user.token}`
			)
		},
		cancel() {
			this.$emit("cancel")
		}
	}
}
</script>

<style lang="less">
.ethical-meeting-add-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
	.empty-label {
		.ivu-form-item-label {
			padding-bottom: 24px;
		}
		.ivu-form-item-label:before {
			display: none;
		}
	}
	.file-item {
		padding: 2px 10px;
		line-height: 24px;
		border-radius: 6px;
		overflow: hidden;
		&:hover {
			background-color: #eee;
			.file-item-name {
				color: #2d8cf0;
			}
			.file-item-actions {
				display: block;
			}
		}
		.file-item-actions {
			float: right;
			display: none;
			margin-left: 20px;
			.preview-icon {
				cursor: pointer;
				&:hover {
					color: #2d8cf0;
				}
			}
			.download-icon {
				margin-left: 12px;
				cursor: pointer;
				&:hover {
					color: #2d8cf0;
				}
			}
		}
	}
}
</style>
