import { render, staticRenderFns } from "./SupplementaryInfo.vue?vue&type=template&id=33134306"
import script from "./SupplementaryInfo.vue?vue&type=script&lang=js"
export * from "./SupplementaryInfo.vue?vue&type=script&lang=js"
import style0 from "./SupplementaryInfo.vue?vue&type=style&index=0&id=33134306&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\KRHealth\\code\\develop\\kr-web-ctms\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33134306')) {
      api.createRecord('33134306', component.options)
    } else {
      api.reload('33134306', component.options)
    }
    module.hot.accept("./SupplementaryInfo.vue?vue&type=template&id=33134306", function () {
      api.rerender('33134306', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Project/SupplementaryInfo.vue"
export default component.exports