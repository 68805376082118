<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="序列号">
				<Input placeholder="请输入序列号" v-model="searchParams.seq" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="支付类型">
				<Select
					:transfer="true"
					v-model="searchParams.payType"
					placeholder="请选择支付类型"
				>
					<Option value="all">全部</Option>
					<Option :value="1">收入</Option>
					<Option :value="-1">支出</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="状态">
				<Select
					:transfer="true"
					v-model="searchParams.isDel"
					placeholder="请选择状态"
				>
					<Option value="all">全部</Option>
					<Option value="0">正常</Option>
					<Option value="1">已作废</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				@click="handleAddShow"
				v-if="
					projectActionPermissions.indexOf('btn_project_finance_info_add') > -1
				"
				>新增</Button
			>
			<Button
				@click="exportExcel"
				v-if="
					projectActionPermissions.indexOf('btn_project_finance_info_add') > -1
				"
				:loading="excelLoading"
				>导出</Button
			>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			:financeNameList="financeNameList"
			@onOk="handleAddOk"
			:id="updateId"
		></Add>
		<ViewDetail
			:visible="viewVisible"
			@onCancel="viewVisible = false"
			:viewData="viewData"
		></ViewDetail>
		<uploadFile
			:visible="uploadVisible"
			:uploadId="updateId"
			@onCancel="uploadVisible = false"
		></uploadFile>
	</div>
</template>

<script>
import api from "@/api/project/financeInfo"
import publicApi from "@/api/public"
import Add from "./Add.vue"
import ViewDetail from "./ViewDetail.vue"
import uploadFile from "./UploadFile.vue"
import { mapState } from "vuex"
import XLSX from "xlsx"
import Moment from "moment"

const { apiGetPage, apiDelete, apiGetList } = api
const { publicGetDictionaryList } = publicApi

export default {
	name: "index",
	components: {
		Add,
		ViewDetail,
		uploadFile
	},
	data() {
		return {
			listData: [],
			columns: [
				{
					title: "序列号",
					key: "seq",
					minWidth: 150
				},
				{
					title: "时间",
					key: "payTime",
					minWidth: 180
				},
				{
					title: "费用名称",
					key: "nickname",
					minWidth: 150
				},
				{
					title: "费用金额（元）",
					key: "amount",
					minWidth: 180
				},
				{
					title: "收入/支出",
					key: "payType",
					minWidth: 180,
					render: (h, params) =>
						h("span", {}, String(params.row.payType) === "1" ? "收入" : "支出")
				},
				{
					title: "状态",
					key: "status",
					minWidth: 180,
					render: (h, params) =>
						h("span", {}, String(params.row.isDel) === "1" ? "已作废" : "正常")
				},
				{
					title: "操作",
					key: "action",
					width: 160,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{
								label: "查看",
								on: {
									click: this.handleViewShow
								}
							},
							{
								label: "附件",
								on: {
									click: this.handleInvoice
								}
							},
							{
								label: "作废",
								on: {
									click: this.handleDeleteShow
								},
								confirm: {
									title: "您确认要作废这条数据吗？"
								}
							}
						]
						if (params.row.isDel) {
							actionList[2] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			loading: false,
			addVisible: false,
			viewVisible: false,
			updateId: "",
			viewData: {},
			searchParams: {
				seq: "",
				payType: "all",
				isDel: "0"
			},
			oldSearchParams: {
				seq: "",
				payType: "all",
				isDel: "0"
			},
			// 分页
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			financeNameList: [],
			excelLoading: false,
			uploadVisible: false
		}
	},
	created() {
		this.getBaseData()
		this.initList()
	},
	computed: {
		...mapState("project", ["infoHeight"]),
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 146 - this.infoHeight
			}
		}),
		...mapState("permission", ["projectActionPermissions"])
	},
	methods: {
		getBaseData() {
			// 获取数据字典--财务费用名称
			this.$asyncDo(async () => {
				const res = await publicGetDictionaryList({ type: 12 })
				if (res) {
					this.financeNameList = res.data[0].leaf
				}
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					projectId: this.$route.params.projectId,
					...this.oldSearchParams,
					isDel:
						this.oldSearchParams.isDel === "all"
							? ""
							: this.oldSearchParams.isDel,
					payType:
						this.oldSearchParams.payType === "all"
							? ""
							: this.oldSearchParams.payType,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
		},
		// 新增药品成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 重置
		reset() {
			this.searchParams = {
				seq: "",
				payType: "all",
				isDel: "0"
			}
			this.searchHandle()
		},
		// 搜索
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 查看显示
		handleViewShow({ row }) {
			this.viewVisible = true
			this.viewData = row
		},
		// 删除
		handleDeleteShow({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiDelete({
					id: row.id,
					projectId: this.$route.params.projectId
				})
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 导出excel
		exportExcel() {
			this.$asyncDo(async () => {
				this.excelLoading = true
				const res = await apiGetList({
					...this.oldSearchParams,
					isDel:
						this.oldSearchParams.isDel === "all"
							? ""
							: this.oldSearchParams.isDel,
					payType:
						this.oldSearchParams.payType === "all"
							? ""
							: this.oldSearchParams.payType,
					projectId: this.$route.params.projectId
				})
				if (res) {
					const headers = {
						seq: "序列号",
						payTime: "时间",
						nickname: "费用名称",
						amount: "费用金额（元）",
						payType: "收入/支出",
						status: "状态"
					}
					const { data } = res
					const dateStr = Moment().format("YYYY-MM-DD HH:mm:ss")
					const fileName = `财务记录_${dateStr}.xlsx`
					const exportData = []
					exportData.push(Object.values(headers))
					data.forEach(d => {
						const item = []
						Object.keys(headers).forEach(k => {
							if (k === "payType") {
								item.push(String(d.payType) === "1" ? "收入" : "支出")
							} else if (k === "status") {
								item.push(String(d.isDel) === "1" ? "已作废" : "正常")
							} else {
								item.push(d[k])
							}
						})
						exportData.push(item)
					})
					const sheet = XLSX.utils.aoa_to_sheet(exportData)
					const book = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(book, sheet, "财务记录")
					XLSX.writeFile(book, fileName)
				}
				this.excelLoading = false
			})
		},
		// 附件
		handleInvoice({ row }) {
			this.updateId = row.id
			this.uploadVisible = true
		}
	}
}
</script>
